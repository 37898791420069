html,
body{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
$bg-mainColor : #4A55A2;
$bg-frameBgColor :#F1F3FD;
$bg-auxiliaryColor : #FC196E;
$bg-auxiliaryColorSoft : #FFC3CF;
$bg-mainDarkColor : #434A8A;
$bg-softColor : #DEE9FF;
$bg-footerBg : #1E1E1E;
$bg-blackText : #0642CD;
$bg-softText : #928F9A;

$primary-color : #4A55A2;
$secondary-color :#F1F3FD;
$third-color : #FC196E;

$bg-primary: #fef0ef;
$bg-primary-dark : #F56962;
$bg-secondary: #EFEFF6;
$bg-dark: #33333D;
