/** index Header **/
.header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    transition: 0.5s;
    padding-top: 10px;
}
@keyframes headerSticky{
    to{
        transform: translateY(0);
    }
}
.header.sticky{
    transform: translateY(-100%);
    position: fixed;
    padding-top: 0;
    background-color: #fff;
    box-shadow: 0 0 10px #ddd;
    animation: headerSticky 0.3s forwards;
}

.navtop{
    transition: 0.5s;
    padding-inline: 36px;
    padding: 0;
    display:  flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    background-color: #fff;

    .navbar{
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        .navbar-collapse{
            display: flex;
            width: 100%;
                justify-content: flex-end;
            align-items: center;
        }
    }
}
.nav-item{
    color: white;
    font-weight: 400;
    font-size: 16px;
    font-family: "Open Sans",sans-serif;
}
.dropdown-item{
    display: flex;
    justify-content: center;
    color: $primary-color;
    font-weight: 400;
    font-size: 16px;
    font-family: "Open Sans",sans-serif;
}
.dropdown-item:active , :focus{
    display: flex;
    justify-content: center;
    color: $primary-color;
    font-weight: 400;
    background: transparent;
    font-size: 16px;
    font-family: "Open Sans",sans-serif;
}
.header-logo text{
    fill: $primary-color;
}
.navbar-brand{
    padding: 0;
    margin: 0;
}
.navbar-nav{
    margin: 0 auto;
}
.nav-link{
    font-size: 16px;
    color: $secondary-color;
    transition: 0.3s;
}
.dropdown-item{
    text-transform: capitalize;
    color: #0642CD;
}
.dropdown{
    color: white;
    font-family: "Open Sans",sans-serif;

}
.home-img img{
    height: 220px;
    width: 220px;
}
// nav link rotate on hover
.navbar-nav .nav-link .dropdown-icon{
    transition: 0.2s;
}
.header-svg-fill{
    fill: $secondary-color;
}
.header-light{
    .header-logo text,
    .header-svg-fill{
        fill: #fff;
    }
    .nav-link{
        color: #fff;
    }
}
.header-light.sticky,
.header-light[style="background-color: #fff;"]{
    .header-logo text{
        fill: $primary-color;
    }
    .nav-link{
        color: $secondary-color;
    }
    .header-svg-fill{
        fill: $secondary-color;
    }
}
.dropdown-item{
    font-size: 15px;

    color: #0642CD;
}
.dropdown-toggle::after{
    display: none;
}
.navbar-toggler{
    height: 3px;
    width: 30px;
    border-radius: 10px;
    position: relative;
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    transition: 0.5s;
    background-color: transparent;
}
.navbar-toggler::after,
.navbar-toggler::before{
    @extend .navbar-toggler;
    content: "";
    opacity: 1;
    position: absolute;
    background-color: $bg-primary-dark;
}
.navbar-toggler::after{
    transform: rotate(45deg);
}
.navbar-toggler::before{
    transform: rotate(-45deg);
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-toggler.collapsed{
    opacity: 1;
    background-color: $bg-primary-dark;
    &::after,
    &::before{
        top: -7px;
        transform: none;
        transform: rotate(0);
    }
    &::before{
        top: 7px;
    }
}


@media only screen and (max-width: 1200px) {
    .navtop{
        transition: 0.5s;
        display:  flex;
        height: 110px;
        padding-inline: 36px;
        align-items: center;
        .navbar{
            padding: 0;
            margin: 0;
            .navbar-collapse{
                display: flex;
                width: 100%;
                    justify-content: flex-end;
                align-items: center;
            }
        }
    }
}
