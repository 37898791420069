body {
  margin: 0;
}
.paginateArea{
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  .paginations{
    display: flex;
    .numbers {
      background: transparent;
      border: none;
      font-size: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      padding-inline: 8px;
      justify-content: center;
      align-items: center;
      color: #CAC6D4;
      font-family: "Open Sans",sans-serif;
      font-weight: 400;
      margin-left: 4px;
      margin-right: 4px;
      border-radius: 5px;
      cursor: pointer;
    }
    .previous{
      display: flex;
      justify-content: center;
      background: transparent;
      border: none;
      align-items: center;
      padding: 0;
      margin-right: 16px;
    }
    .next{
      display: flex;
      justify-content: center;
      background: transparent;
      align-items: center;
      border: none;
      padding: 0;
      margin-left: 16px;
    }

    button:disabled {
      text-align: center;
      color: #cbd5e0;
    }

    button.active {
      color: #fff;
      background-color: #0642CD;
      margin-left: 16px;
      margin-right: 16px;
    }

  }
}

