.footer-link{
  font-size: 18px;
  &:hover{
    color: #fff;
  }
}
.footerBg{
  background-color: #EFF2FF;
  position: relative;
  .footer{
    background-color: #EFF2FF;
    display: flex;
    justify-content: center;
    min-height: 578px;
    padding-block: 20px;
    width: 100%;
    .sec-heading{
      padding-top: 0;
      font-size: 32px;
      font-weight: 600;
      font-family: "Open Sans",sans-serif;
      color: #103F9F;
      line-height: 41px;
    }
    .sec-brief{
      color:#7185B8;
      padding-top: 18px;
      padding-bottom: 59px;
      font-size: 18px;
      font-weight: 400;
      font-family: "Open Sans",sans-serif;
    }
    .firstRow{
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 35px;
    }
    .emailform,.subjform{
      position: relative;
      width: 100%;
      z-index: 3;
      .email, .subject{
        height: 56px;
        width: 100%;
        padding-left: 20px;
        color: #0E2954;
        background-color: transparent;
        border: 1px solid #7185B8;
        font-family: "Open Sans", "sans-serif";
        font-size: 18px;
        box-shadow: none;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .textform{
      padding-top: 20px;
      margin-top: 0;
      .textarea{
        background-color: transparent;
        border: 1px solid #7185B8;
        margin: 0;
        box-shadow: none;
        color: #0E2954;
        font-size: 18px;
        height: 120px;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .buttonSend{
      margin-top: 42px;
      margin-bottom: 0px;
      .buttonns{
        width: 181px;
        font-family: "Open Sans", "sans-serif";
        font-size: 18px;
        font-weight: 500;

      }
      .buttonns:hover,:active,:focus{
        background-color: #0642CD;
        box-shadow: 0 10px 20px rgba(6, 66, 205, .25);

      }
    }

  }
  .copyright{
    height: 104px;
    width: 100%;
    background-color: #103F9F;
    .copyrightInline{
      color: white;
      background-color: #103F9F;
      font-family: "Poppins",sans-serif;
      margin-block: 0;
      width: 100%;
      max-width: 1170px;
      .copyText{
        font-family: "Open Sans",sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
      .icons{
        gap: 12px;
      }
    }
  }
}


@media only screen and (max-width: 1200px){
  .footerBg{
    .footer{
      width: auto;
      margin-inline: 36px;
      .sec-heading{
        padding-top: 64px;
        font-size: 28px;
        font-weight: 600;
        font-family: "Open Sans",sans-serif;
        color: #103F9F;
        line-height: 41px;
      }
      .sec-brief{
        color: #7185B8;
        padding-top: 14px;
        padding-bottom: 59px;
        font-size: 16px;
        font-weight: 400;
        font-family: "Open Sans",sans-serif;
      }
      .firstRow{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        padding: 0;
        gap: 20px;
      }
      .emailform,.subjform{
        position: relative;
        width: 100%;
        z-index: 3;
        .email, .subject{
          height: 56px;
          width: 100%;
          padding-left: 20px;
          color: #0E2954;
          background-color: transparent;
          border: 1px solid #7185B8;
          font-family: "Open Sans", "sans-serif";
          font-size: 16px;
          box-shadow: none;
        }
        ::placeholder{
          color: #7185B8;
          font-size: 14px;
          font-weight: 400;
          font-family: "Open Sans", "sans-serif";
        }
      }
      .textform{
        padding-top: 20px;
        margin-top: 0;
        .textarea{
          background-color: transparent;
          border: 1px solid #7185B8;
          margin: 0;
          padding-inline: 20px;
          padding-top: 14px;
          box-shadow: none;
          color: #0E2954;
          font-size: 16px;
          height: 120px;
        }
        ::placeholder{
          color: #7185B8;
          font-size: 14px;
          font-weight: 400;
          font-family: "Open Sans", "sans-serif";
        }
      }
      .buttonSend{
        margin-top: 36px;
        margin-bottom: 57px;
        .buttonns{
          padding-block: 15px;
          font-size: 16px;
          font-family: "Open Sans", "sans-serif";
          padding-inline: 24px;
        }
      }
    }
    .copyright{
       display: flex;
       justify-content: center;
       align-items: center;
       height: 100px;
      flex-wrap: wrap;
       background-color: #103F9F;
      padding-inline: 36px;
       .copyrightInline{
         color: white;
         flex-wrap: wrap;
         display: flex;
         height: auto;
         justify-content: space-between;
         padding: 0;
         width: 100%;
         max-width: 1170px;
         .copyText{
           font-family: "Open Sans",sans-serif;
           font-size: 16px;
           font-weight: 400;
           margin-inline: 100px;
           margin: 0;
         }
         .icons{
           justify-content: space-evenly;
           width: auto;
           gap: 30px;
         }
       }
     }
  }

}
@media only screen and (max-width: 768px){
  .footer{
    width: 100%;
    max-width: 1170px;
    padding-inline: 20px;
    .sec-heading{
      padding-top: 64px;
      font-size: 24px;
      font-weight: 600;
      font-family: "Open Sans",sans-serif;
      color: #103F9F;
      line-height: 41px;
    }
    .sec-brief{
      color: #7185B8;
      padding-top: 18px;
      padding-bottom: 59px;
      font-size: 18px;
      font-weight: 400;
      font-family: "Open Sans",sans-serif;
    }
    .firstRow{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      padding: 0;
      gap: 20px;
    }
    .emailform,.subjform{
      position: relative;
      width: 100%;
      z-index: 3;
      .email, .subject{
        height: 56px;
        width: 100%;
        padding-left: 20px;
        color: #0E2954;
        background-color: transparent;
        border: 1px solid #7185B8;
        font-family: "Open Sans", "sans-serif";
        font-size: 14px;
        box-shadow: none;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .textform{
      padding-top: 20px;
      margin-top: 0;
      .textarea{
        background-color: transparent;
        border: 1px solid #7185B8;
        margin: 0;
        box-shadow: none;
        color: #0E2954;
        font-size: 14px;
        height: 120px;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .buttonSend{
      margin-top: 34px;
      margin-bottom: 57px;
      .buttonns{
        padding-block: 15px;
        padding-inline: 24px;
      }
    }
    .copyright{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      background-color: #103F9F;
      padding-inline: 36px;
      .copyrightInline{
        color: white;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column-reverse !important;
        height: auto;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        max-width: 1170px;
        .copyText{
          font-family: "Open Sans",sans-serif;
          font-size: 16px;
          font-weight: 400;
          margin-inline: 100px;
          margin: 0;
        }
        .icons{
          justify-content: space-evenly;
          width: auto;
          gap: 30px;
        }
      }
    }
  }
}
@media only screen and (max-width: 572px){
  .footer{
    width: 100%;
    max-width: 1170px;
    padding-inline: 20px;
    .sec-heading{
      padding-top: 64px;
      font-size: 24px;
      font-weight: 600;
      font-family: "Open Sans",sans-serif;
      color: #103F9F;
      line-height: 41px;
    }
    .sec-brief{
      color: #7185B8;
      padding-top: 18px;
      padding-bottom: 59px;
      font-size: 18px;
      font-weight: 400;
      font-family: "Open Sans",sans-serif;
    }
    .firstRow{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      padding: 0;
      gap: 20px;
    }
    .emailform,.subjform{
      position: relative;
      width: 100%;
      z-index: 3;
      .email, .subject{
        height: 56px;
        width: 100%;
        padding-left: 20px;
        color: #0E2954;
        background-color: transparent;
        border: 1px solid #7185B8;
        font-family: "Open Sans", "sans-serif";
        font-size: 14px;
        box-shadow: none;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .textform{
      padding-top: 20px;
      margin-top: 0;
      .textarea{
        background-color: transparent;
        border: 1px solid #7185B8;
        margin: 0;
        box-shadow: none;
        color: #0E2954;
        font-size: 14px;
        height: 120px;
      }
      ::placeholder{
        color: #7185B8;
        font-size: 14px;
        font-weight: 400;
        font-family: "Open Sans", "sans-serif";
      }
    }
    .buttonSend{
      width: 100%;
      margin-top: 34px;
      margin-bottom: 57px;
      .buttonns{
        padding-block: 15px;
        width: 100% !important;
        padding-inline: 24px;
      }
    }
    .copyright{
      height: 104px;
      width: 100%;
      background-color: #103F9F;
      .copyrightInline{
        color: white;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins",sans-serif;
        margin-block: 0;
        width: 100%;
        max-width: 1170px;
        .copyText{
          font-family: "Open Sans",sans-serif;
          font-size: 18px;
          padding-bottom: 20px;
          font-weight: 400;
        }
        .icons{
          margin-top: 20px;
          gap: 12px;
        }
      }
    }
  }
}

.footerBg{
  position: relative;
}
.footerBg:before{
  position: absolute;
  z-index: 9999!important;
  content: "";
  top:54px;
  left:0;
  width: 104px;
  height: 204px;
  background-image: url(../images/svg/dots-pattern-gray-vertical.svg);
}
.footerBg:after{
  position: absolute;
  z-index: 9999!important;
  content: "";
  top:182px;
  right:0;
  width: 104px;
  height: 204px;
  background-image: url(../images/svg/dots-pattern-gray-vertical.svg);
}

@media only screen and (max-width: 1438px){
  .footerBg:before, .footerBg:after{
    display: none;
  }
}
@media only screen and (max-width: 475px){
.copyright{
  height: 104px;
  width: 100%;
  background-color: #103F9F;
  .copyrightInline{
    color: white;
    background-color: #103F9F;
    font-family: "Poppins",sans-serif;
    margin-block: 0;
    width: 100%;

    .copyText{
      font-family: "Open Sans",sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
    }
    .icons{
      gap: 12px;
    }
  }
}
}
