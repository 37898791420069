.footer-link{
    color: $third-color;
    font-size: 18px;
    &:hover{
        color: #fff;
    }
}
.footerBg{

    background-color: #EFF3FD;
    .footer{
        background-color: #EFF3FD;
        width: 100%;
        .sec-heading{
            padding-top: 0;
            margin-top: 0;
            font-size: 32px;
            font-weight: 600;
            font-family: "Open Sans",sans-serif;
            color: #0642CD;
            line-height: 41px;
        }
        .sec-brief{
            padding-top: 18px;
            padding-bottom: 0;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 400;
            color: #928F9A;
            font-family: "Open Sans",sans-serif;
        }
        .firstRow{
            width: 100%;
            margin-bottom: 0;
            gap: 38px;
            padding-bottom: 35px;
        }
        .emailform,.subjform{
            display: flex;
            width: 100%;
            position: relative;
            z-index: 3;
            .email, .subject{
                height: 56px;
                width: 100%;
                display: flex;
                padding-left: 24px;
                background-color: transparent;
                border: 1px solid #928F9A;
                font-size: 14px;
            }
        }
        .footerBg .footer .sec-heading {
            padding-top: 0px;
            font-size: 32px;
            font-weight: 600;
            font-family: "Open Sans",sans-serif;
            color: #0642CD;
            line-height: 41px;
        }
        .textform{
            width: 100%;
            .textarea{
                width: 100%;
                background-color: transparent;
                border: 1px solid #928F9A;
                margin: 0;
                font-size: 14px;
                height: 120px;
            }
        }
        .buttonSend{
            margin-top: 42px;
            margin-bottom: 57px;
            .buttonns{
                background: #0642CD;
                border: none;
                font-size: 18px;
                font-weight: 500;
                font-family: "Open Sans",sans-serif;

                padding-block: 15px;
                padding-inline: 24px;
            }
        }

    }
    .copyright{
         height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
         width: 100%;
         background-color: #4A55A2;
         .copyrightInline{
             color: white;
             display: flex;
             justify-content: space-between;
             align-items: center;
             font-family: "Poppins",sans-serif;
             width: 100%;
             max-width: 1170px;
             .copyText{
                 font-family: "Open Sans",sans-serif;
                 font-size: 18px;
                 font-weight: 400;
                 margin: 0;
             }
             .icons{

                 gap: 20px;
             }
         }
     }
}


@media only screen and (max-width: 1200px){
    .footer{
        width: 100%;
        max-width: 1170px;
        padding-inline: 50px;
        .sec-heading{
            padding-top: 64px;
            font-size: 32px;
            font-weight: 600;
            font-family: "Open Sans",sans-serif;
            color: #0642CD;
            line-height: 41px;
        }
        .sec-brief{
            padding-top: 18px;
            padding-bottom: 59px;
            font-size: 18px;
            font-weight: 400;
            font-family: "Open Sans",sans-serif;
        }
        .firstRow{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            padding: 0;
            gap: 20px;
        }
        .emailform,.subjform{
            position: relative;
            width: 100%;
            z-index: 3;
            .email, .subject{
                height: 56px;
                width: 100%;
                padding-left: 24px;
                background-color: transparent;
                border: 1px solid #928F9A;
                font-size: 14px;
            }
        }
        .textform{
            padding-top: 20px;
            margin-top: 0;
            .textarea{
                background-color: transparent;
                border: 1px solid #928F9A;
                margin: 0;
                font-size: 14px;
                height: 120px;
            }
        }
        .buttonSend{
            margin-top: 42px;
            margin-bottom: 57px;
            .buttonns{
                padding-block: 15px;
                padding-inline: 24px;
            }
        }
        .copyright{
            display: flex;
            justify-content: center;
            align-items: center;
            height: fit-content;

            background-color: #4A55A2;
            .copyrightInline{
                color: white;
                display: flex;
                flex-direction: column;
                font-family: "Poppins",sans-serif;
                margin-block: 0;
                width: 100%;
                max-width: 1170px;
                margin-inline: 100px;
                .copyText{
                    font-family: "Open Sans",sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    margin-inline: 100px;
                    margin: 0;
                }
                .icons{
                    gap: 12px;
                    padding-inline: 100px;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .footer{
        width: 100%;
        max-width: 1170px;
        padding-inline: 20px;
        .sec-heading{
            padding-top: 64px;
            font-size: 24px;
            font-weight: 600;
            font-family: "Open Sans",sans-serif;
            color: #0642CD;
            line-height: 41px;
        }
        .sec-brief{
            padding-top: 18px;
            padding-bottom: 59px;
            font-size: 18px;
            font-weight: 400;
            font-family: "Open Sans",sans-serif;
        }
        .firstRow{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            padding: 0;
            gap: 20px;
        }
        .emailform,.subjform{
            position: relative;
            display: flex;
            width: 100%;
            z-index: 3;
            .email, .subject{
                height: 56px;
                width: 100%;
                padding-left: 24px;
                background-color: transparent;
                border: 1px solid #928F9A;
                font-size: 14px;
            }
        }
        .textform{
            padding-top: 20px;
            margin-top: 0;
            .textarea{
                background-color: transparent;
                border: 1px solid #928F9A;
                margin: 0;
                font-size: 14px;
                height: 120px;
            }
        }
        .buttonSend{
            margin-top: 42px;
            margin-bottom: 57px;
            .buttonns{
                padding-block: 15px;
                padding-inline: 24px;
            }
        }
        .copyright{
            height: 104px;
            width: 100%;
            background-color: #4A55A2;
            .copyrightInline{
                color: white;
                font-family: "Poppins",sans-serif;
                margin-block: 0;
                width: 100%;
                max-width: 1170px;
                .copyText{
                    font-family: "Open Sans",sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    margin: 0;
                }
                .icons{
                    gap: 12px;
                }
            }
        }
    }
}


