.topArea {
  background-size: contain;
  background-repeat: no-repeat;

}

.account {
  width: 100%;
  height: fit-content;
  background-color: #F9FAFF;
  display: flex;
  position: relative;
  justify-content: center;


  .decore {
    background-color: #103F9F;
    width: 100%;
    height: 201px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 0;
    left: 0;

    .dots-left {
      height: 100%;
      display: flex;
      margin-top: 6px;
      width: fit-content;
    }

    .dots-right {
      height: 100%;
      display: flex;
      position: relative;
      margin-top: 6px;
      width: fit-content;
    }
  }

  .accountContent {
    height: 100%;
    position: relative;
    background-color: transparent;
    display: flex;
    justify-content: center;
    width: 100%;
    border: none;
    z-index: 2;

    .wraper {
      height: 100%;
      position: relative;
      margin-top: 75px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      width: 100%;
      border: none;
      z-index: 2;

      .statsWraper {
        background-color: #fff;
        height: fit-content;
        width: 100%;

        .stats {
          background-color: #fff;
          height: 215px;
          width: 100%;

          .stats-top {
            padding-inline: 52px;
            height: 149px;

            .sections {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              height: 100%;

              .data-area {
                gap: 45px;
                text-align: center;

                .data-wraper {
                  display: flex;
                  position: relative;
                  gap: 60px;

                  .stats-data {
                    font-size: 15px;
                  }
                }
              }
            }
          }

          .langs {
            padding-inline: 52px;
            border: none;
            display: inherit;
            align-items: center;
              justify-content: flex-start;
            gap: 60px;
            margin: 0;

            nav {
              padding: 0;
              margin: 0;
              height: 100%;
              display: flex;
            }

            .nav-tabs {
              display: flex;
              gap: 60px;
              height: 100%;
              padding: 0;
              margin: 0;
              align-items: center;
              background: transparent !important;
              border-bottom: 1px solid transparent !important;
            }

            .nav-tabs2 {
              display: flex;
              height: 52px;
              padding: 0;
              row-gap: 0;
              column-gap: 20px;
              margin: 0;
              width: 100%;
              align-items: center;
                  justify-content: flex-end;
              background: transparent !important;
              border: none !important;
            }

            .nav-tabs:hover {
              background: transparent !important;
              border: none;
              height: 100%;
              border-bottom: 1px solid black;
            }

            .nav-link {
              background: transparent !important;
              border: none;
              border-bottom: 2px solid transparent;
              border-spacing: 20px;
              padding: 20px 0 10px 0;
              border-radius: 0;
              height: 100%;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              color: #7185B8;
            }

            .nav-link:hover {
              background: transparent !important;
              border: none;
              border-bottom: 2px solid #0642CD;
              border-spacing: 20px;
              color: #0642CD;
              padding: 20px 0 10px;
              height: 100%;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              border-radius: 0;
            }

            .nav-link:focus {
              background: transparent !important;
              border: none;
              border-bottom: 2px solid #0642CD;
              border-spacing: 20px;
              color: #0642CD;
              height: 100%;
              padding: 20px 0 10px;
              font-family: "Open Sans", sans-serif;
              font-weight: 600;
              border-radius: 0;
            }

            .nav-link.active {
              background: transparent !important;
              border: none;
              border-bottom: 2px solid #0642CD;
              border-spacing: 20px;
              color: #0642CD;
              height: 100%;
              padding: 20px 0 10px;
              font-family: "Open Sans", sans-serif;
              font-weight: 600;
              border-radius: 0;
            }

            .lang-tabs {
              display: flex;
              gap: 60px;
            }
          }

          .course-desc {
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #7185B8;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;


          }


        }
      }

      .contentWraper {
        height: 100%;
        display: flex;

        .postContent {
          width: 100%;
          height: 100%;
          display: flex;

          .tabWraper {
            width: 100%;
            height: 100%;

            .tab-pane {
              width: 100%;
              height: 100%;

              h1 {
                font-family: "Open Sans", sans-serif;
              }

              .tab-content {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                margin-top: 66px;
                padding-bottom: 48px;

                .tab-heading {
                  padding-top: 32px;
                }

                .tab-description {
                  height: fit-content;
                  border: none;
                  gap: 9px;
                  display: flex;
                  position: relative;
                  justify-content: center;
                  align-items: center;

                  .btn {
                    background-color: transparent !important;
                    border: 1px solid #0642CD;
                    font-size: 18px;
                    text-transform: capitalize;
                    font-family: "Open Sans", "sans-serif";
                    font-weight: 400;
                    padding-block: 15px;
                    padding-inline: 16px;
                    transition: 0.5s;
                    box-shadow: 0 0 15px #ececea;
                    color: #0642CD;

                    p {
                      color: #0642CD;
                      fill: #0642CD;
                      gap: 9px;
                    }
                  }

                  .dropdown-toggle:hover {
                    background-color: #0642CD !important;
                    border: 1px solid transparent;
                    color: #0642CD;
                    font-weight: 400;
                    text-transform: capitalize;
                    font-family: "Open Sans", "sans-serif";
                    transition: all 0.5s;
                    box-shadow: 0 10px 20px rgba(43, 52, 132, 0.25);

                    p {
                      color: white;
                      fill: white;
                      font-family: "Open Sans", "sans-serif";
                      gap: 9px;
                      font-weight: 400;
                      box-shadow: none;
                      background-color: transparent !important;
                      border: none;
                    }
                  }
                }

                .dropdown-menu {
                  background-color: white !important;
                  width: 100%;
                  box-shadow: none;

                  .dropdown-item {
                    color: #2B3484;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                  }

                  .dropdown-item:focus, :active {
                    background-color: white !important;
                    box-shadow: none !important;
                    border: none !important;
                  }

                  p {
                    color: #2B3484;
                  }
                }

                .dropdown-menu:active, :focus {
                  background-color: white !important;
                  box-shadow: none;
                  border: none;

                  .dropdown-item {
                    color: #2B3484;
                  }

                  p {
                    color: #2B3484;
                  }
                }
              }

              .postsNull {
                height: 150px;
                width: 100%;

                .noneOfPosts {
                  width: 50%;
                  max-width: 585px;
                  height: 50%;
                  user-select: none;
                  max-height: 75px;
                  //border: #FC196E 2px solid;
                  background-color: transparent;
                  border-radius: 5px;
                  font-family: "Open Sans", "sans-serif";
                  font-size: 18px;
                  font-weight: 400;
                  color: #FC196E;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-transform: capitalize;
                  transition: all 0.5s;
                  box-shadow: none;
                }
              }

              .posts {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                gap: 30px;

                .col {
                  max-width: 370px;
                  padding-inline: 0;
                  padding-bottom: 0;
                    justify-content: flex-start;
                  text-decoration: none;
                }

                .cards {
                  max-height: 375px;
                  background-color: white;
                  padding: 0;
                  display: block;
                  margin: 0;
                  flex-wrap: wrap;
                  border: none;
                  text-decoration: none;
                  transition: 0.5s;
                  box-shadow: 0 0 15px #ececea;

                  img {
                    width: 100%;
                    height: 189px;
                  }

                  .playCase {
                    width: 100%;
                    min-height: 52px;
                    padding: 0 25px 0 0;
                    display: flex;
                    align-content: stretch;
                    background: transparent;
                    position: inherit;
                        justify-content: flex-end;
                    margin-top: -26px;

                    .play {
                      width: 52px;
                      height: 52px;
                      border-radius: 50px;
                      background: #FFF;
                      display: flex;
                      position: absolute;
                      justify-content: center;
                      align-items: center;
                      box-shadow: 0 2px 8px rgba(91, 109, 249, 0.2);

                      .playButton {
                        width: 100%;

                      }
                    }
                  }

                  .content {
                    margin-top: -26px;
                    height:190px;
                    height:190px; min-height: 190px;
                    padding-inline: 36px;
                    gap: 10px;
                    text-decoration: none;
                    text-underline: none;

                    .course-title {
                      margin-top: 30px;
                      margin-bottom: 0;
                      font-family: "Open Sans", sans-serif;
                      text-decoration: none;
                      text-underline: none;
                      font-size: 22px;
                      font-weight: 600;
                      color: #103F9F;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      transition: all 0.5s;
                    }

                    .course-desc {
                      padding-top: 16px;
                      text-decoration: none;
                      text-underline: none;
                    }
                  }
                }

                .cards:hover {
                  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
                  transform: translateY(-15px);
                }
              }
            }
          }
        }
      }
    }

    .pagination {
      width: 100%;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  hr {
    padding: 0;
    margin: 0;
    background-color: #D9D9D9;
    height: 1px;
  }

  h1, h2, h3, h4 {
    color: var(--Black-Text, #103F9F);
    font-family: "Open Sans", sans-serif;
  }

  h1 {
    padding: 0;
    margin: 0;
    color: var(--Black-Text, #103F9F);
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tab-pane {
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }

  h4 {
    padding: 15px 0 0 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }

  .course-desc {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #7185B8;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  nav {
    background: transparent !important;
    border: none !important;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #CAC6D4 !important;

    ul {
      background-color: transparent !important;
      gap: 30px;
      justify-content: center;
      align-items: center;

      li {
        background: transparent !important;
        border: none !important;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #CAC6D4 !important;

        a {
          background: transparent !important;
          border: none !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          margin: 0 !important;
          color: #CAC6D4 !important;
          padding: 0 !important;
        }

        a.active {
          background: transparent !important;
          border: none !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          margin: 0 !important;
          color: white !important;
          padding: 0 !important;
        }
      }

      li:active {
        background-color: #103F9F !important;
        display: flex;
        width: 32px;
        height: 32px;
        margin: 0;
        color: white !important;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
      }

      li.active {
        background-color: #103F9F !important;
        display: flex;
        width: 32px;
        height: 32px;
        margin: 0;
        color: white !important;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
      }

      a:focus {
        box-shadow: none !important;
      }
    }
  }
}

@media only screen and (max-width: 991px) {

  .account {
    width: 100%;
    height: fit-content;
    background-color: #F9FAFF;
    display: flex;
    position: relative;
    justify-content: center;

    .decore {
      background-color: #103F9F;
      width: 100%;
      height: 201px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;

      .dots-left {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
      }

      .dots-right {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
      }
    }

    .accountContent {
      height: 100%;
      position: relative;
      background-color: transparent;
      display: flex;
      justify-content: center;
      width: 100%;
      border: none;
      z-index: 2;

      .wraper {
        height: 100%;
        position: relative;
        margin-top: 75px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        width: 100%;
        border: none;
        z-index: 2;

        .statsWraper {
          background-color: #fff;
          height: fit-content;
          width: 100%;

          .stats {
            background-color: #fff;
            height: 215px;
            width: 100%;

            .stats-top {
              padding-inline: 52px;
              height: 149px;

              .sections {
                display: flex;

                .data-area {
                  gap: 45px;
                  text-align: center;

                  .data-wraper {
                    display: flex;
                    position: relative;
                    gap: 60px;

                    .stats-data {
                      font-size: 15px;
                    }
                  }
                }
              }
            }

            .langs {
              border: none;
              display: flex;
              height: 64px;
              align-items: center;
              padding: 0;
              margin: 0;

              .lang-tabs {
                padding-inline: 36px;
                height: 100%;
                display: flex;
                align-items: center;
                    justify-content: flex-end;
                width: 100%;

                nav {
                  padding: 0;
                  margin: 0;
                  gap: 0;
                  height: 100%;
                  display: flex;
                }

                .nav-tabs {
                  display: flex;
                  height: 100%;
                  padding: 0;
                  row-gap: 0;
                  column-gap: 20px;
                  margin: 0;
                  width: 100%;
                  align-items: center;
                  justify-content: space-evenly;
                  background: transparent !important;
                  border-bottom: 1px solid transparent !important;
                }

                .nav-tabs2 {
                  height: fit-content;
                  width: fit-content;
                  gap: 9px;
                  display: flex;
                  position: relative;
                  justify-content: space-between;
                  align-items: center;
                  padding-inline: 16px;
                  border-radius: 5px;

                  .dropdown-toggle {
                    background-color: transparent !important;
                    width: 135px;
                    height: 53px;
                    font-size: 18px;
                    text-transform: capitalize;

                    font-family: "Open Sans", "sans-serif";
                    font-weight: 400;
                    padding-right: 0;
                    color: #103F9F;
                  }
                }

                .nav-tabs:hover {
                  background: transparent !important;
                  border: none;
                  height: 100%;
                  border-bottom: 1px solid black;
                }

                .select {
                  justify-self: end;
                  display: flex;
                  width: 180px;
                  height: 40px;
                  justify-content: center;
                  text-align: center;
                  border-radius: 5px;
                  font-family: "Open Sans", sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  border: 1px solid #928F9A;
                  color: #103F9F;
                  outline: none;
                }

                .nav-link {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid transparent;
                  border-spacing: 20px;
                  padding: 20px 0 10px 0;
                  border-radius: 0;
                  height: 100%;
                  font-size: 14px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  color: #7185B8;
                }

                .nav-link:hover {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #4A55A2;
                  padding: 20px 0 10px;
                  height: 100%;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  border-radius: 0;
                }

                .nav-link:focus {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #0642CD;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

                .nav-link.active {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #0642CD;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

              }

            }

            .course-desc {
              font-family: "Open Sans", sans-serif;
              font-size: 14px;
              font-weight: 400;
              color: #7185B8;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;


            }


          }
        }

        .contentWraper {
          height: 100%;
          display: flex;

          .postContent {
            padding-inline: 36px !important;
            width: 100%;
            height: 100%;
            display: flex;

            .tabWraper {
              width: 100%;
              height: 100%;

              .tab-pane {
                width: 100%;
                height: 100%;

                .tab-content {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 66px;
                  padding-bottom: 48px;

                  .tab-heading {
                    padding-top: 32px;
                  }

                  .tab-description {
                    height: fit-content;
                    border: none;
                    gap: 9px;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;

                    .btn {
                      background-color: transparent !important;
                      border: 1px solid #103F9F;
                      font-size: 18px;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                      font-weight: 400;
                      padding-block: 15px;
                      padding-inline: 16px;
                      transition: 0.5s;
                      box-shadow: 0 0 15px #ececea;
                      color: #103F9F;

                      p {
                        color: #103F9F;
                        fill: #103F9F;
                        gap: 9px;
                      }
                    }

                    .dropdown-toggle:hover {
                      background-color: #0642CD !important;
                      border: 1px solid transparent;
                      color: #0642CD;
                      font-weight: 400;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                      transition: all 0.5s;
                      box-shadow: 0 10px 20px rgba(43, 52, 132, 0.25);

                      p {
                        color: white;
                        fill: white;
                        font-family: "Open Sans", "sans-serif";
                        gap: 9px;
                        font-weight: 400;
                        box-shadow: none;
                        background-color: transparent !important;
                        border: none;
                      }
                    }
                  }

                  .dropdown-menu {
                    background-color: white !important;
                    box-shadow: none;

                    .dropdown-item {
                      color: #2B3484;
                      padding: 0;
                    }

                    .dropdown-item:focus, :active {
                      background-color: white !important;
                      box-shadow: none !important;
                      border: none !important;
                    }

                    p {
                      color: #2B3484;
                    }
                  }

                  .dropdown-menu:active, :focus {
                    background-color: white !important;
                    box-shadow: none;
                    border: none;

                    .dropdown-item {
                      color: #2B3484;
                    }

                    p {
                      color: #2B3484;
                    }
                  }
                }
              }

              .posts {
                width: 100%;
                height: 100%;
                padding: 0;
                gap: 0 !important;
                margin: 0;

                .col {
                  max-width: 370px;
                  padding-inline: 10px !important;
                  padding-bottom: 20px !important;
                    justify-content: flex-start;
                }

                .cards {
                  max-height: 375px;
                  background-color: white;
                  padding: 0;
                  display: block;
                  margin: 0;
                  flex-wrap: wrap;
                  border: none;
                  text-decoration: none;
                  transition: 0.5s;
                  box-shadow: 0 0 15px #ececea;

                  img {
                    width: 100%;
                    height: 189px;
                  }

                  .playCase {
                    width: 100%;
                    min-height: 52px;
                    padding: 0 25px 0 0;
                    display: flex;
                    align-content: stretch;
                    background: transparent;
                    position: inherit;
                        justify-content: flex-end;
                    margin-top: -26px;

                    .play {
                      width: 52px;
                      height: 52px;
                      border-radius: 50px;
                      background: #FFF;
                      display: flex;
                      position: absolute;
                      justify-content: center;
                      align-items: center;
                      box-shadow: 0 2px 8px rgba(91, 109, 249, 0.2);

                      .playButton {
                        width: 100%;

                      }
                    }
                  }

                  .content {
                    margin-top: -26px;
                    padding: 0;
                    height:190px; min-height: 190px;
                    padding-inline: 36px;
                    gap: 10px;

                    .course-title {
                      margin-top: 30px;
                      margin-bottom: 0;
                      font-family: "Open Sans", sans-serif;
                      font-size: 22px;
                      font-weight: 600;
                      color: #103F9F;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      transition: all 0.5s;
                    }

                    .course-desc {
                      padding-top: 16px;
                    }
                  }
                }

                .cards:hover {
                  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
                  transform: translateY(-15px);
                }
              }
            }
          }
        }
      }

      .pagination {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    hr {
      padding: 0;
      margin: 0;
      background-color: #D9D9D9;
      height: 1px;
    }

    h1, h2, h3, h4 {
      color: var(--Black-Text, #103F9F);
      font-family: "Open Sans", sans-serif;
    }

    h1 {
      padding: 0;
      margin: 0;
      color: var(--Black-Text, #103F9F);
      font-family: 'Open Sans', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .tab-pane {
    }

    h2 {
      padding: 0;
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }

    h4 {
      padding: 15px 0 0 0;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }

    .course-desc {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #7185B8;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    nav {
      background: transparent !important;
      border: none !important;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #CAC6D4 !important;

      ul {
        background-color: transparent !important;
        gap: 30px;
        justify-content: center;
        align-items: center;

        li {
          background: transparent !important;
          border: none !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #CAC6D4 !important;

          a {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: #CAC6D4 !important;
            padding: 0 !important;
          }

          a.active {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: white !important;
            padding: 0 !important;
          }
        }

        li:active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        li.active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        a:focus {
          box-shadow: none !important;
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {

  .account {
    width: 100%;
    height: fit-content;
    background-color: #F9FAFF;
    display: flex;
    position: relative;
    justify-content: center;

    .decore {
      background-color: #103F9F;
      width: 100%;
      height: 201px;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      display: none;

      .dots-left {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
        display: none;
      }

      .dots-right {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
        display: none;
      }
    }

    .accountContent {
      height: 100%;
      position: relative;
      background-color: transparent;
      display: flex;
      margin-top: 0;
      justify-content: center;
      width: 100%;
      border: none;
      z-index: 2;

      .wraper {
        height: 100%;
        position: relative;
        margin-top: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        width: 100%;
        border: none;
        z-index: 2;

        .statsWraper {
          background-color: #fff;
          height: fit-content;
          width: 100%;

          .stats {
            background-color: #fff;
            display: block;
            height: fit-content;
            width: 100%;

            .stats-top {
              height: fit-content;
              display: block;

              .sections {
                display: flex;
                flex-direction: column;

                .title {
                  justify-content: center;
                  text-align: center;
                  align-items: center;
                  width: inherit;
                  margin-block: 36px;
                }

                .data-area {
                  width: 100%;
                  display: flex;
                  text-align: center;
                  margin-bottom: 32px;

                  .data-wraper {
                    width: 100%;
                    display: flex;
                    position: relative;
                    justify-content: space-evenly;
                    gap: 0;

                    .stats-data {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .langs {
              border: none;
              display: flex;
              height: 64px;
              align-items: center;
              padding: 0;
              margin: 0;

              .lang-tabs {
                padding-inline: 36px;
                height: 100%;
                display: flex;
                align-items: center;
                    justify-content: flex-end;
                width: 100%;

                nav {
                  padding: 0;
                  margin: 0;
                  gap: 0;
                  height: 100%;
                  display: flex;
                }

                .nav-tabs {
                  display: flex;
                  height: 100%;
                  padding: 0;
                  row-gap: 0;
                  column-gap: 20px;
                  margin: 0;
                  width: 100%;
                  align-items: center;
                  justify-content: space-evenly;
                  background: transparent !important;
                  border-bottom: 1px solid transparent !important;
                }

                .nav-tabs2 {
                  height: fit-content;
                  width: fit-content;
                  border: 1px solid #103F9F;
                  gap: 9px;
                  display: flex;
                  position: relative;
                  justify-content: space-between;
                  align-items: center;
                  padding-inline: 0;
                  border-radius: 0;
                  border-bottom: none !important;

                  .dropdown-toggle {
                    background-color: transparent !important;
                    width: 135px;
                    height: 53px;
                    font-size: 18px;
                    text-transform: capitalize;
                    border: 1px solid #103F9F;
                    font-family: "Open Sans", "sans-serif";
                    font-weight: 400;
                    padding-right: 0;
                    color: #103F9F;
                  }

                  :focus {
                    box-shadow: none;
                    outline: none;
                  }

                  .dropdown-menu {
                    width: 135px;
                    justify-content: center;
                    padding: 10px;

                    .dropdown-item {
                    }

                    :focus {
                      background-color: transparent;
                      color: #103F9F;
                    }
                  }
                }

                .nav-tabs:hover {
                  background: transparent !important;
                  border: none;
                  height: 100%;
                  border-bottom: 1px solid black;
                }

                .select {
                  justify-self: end;
                  display: flex;
                  width: 180px;
                  height: 40px;
                  justify-content: center;
                  text-align: center;
                  border-radius: 5px;
                  font-family: "Open Sans", sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  border: 1px solid #928F9A;
                  color: #103F9F;
                  outline: none;
                }

                .nav-link {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid transparent;
                  border-spacing: 20px;
                  padding: 20px 0 10px 0;
                  border-radius: 0;
                  height: 100%;
                  font-size: 14px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  color: #7185B8;
                }

                .nav-link:hover {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #4A55A2;
                  padding: 20px 0 10px;
                  height: 100%;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  border-radius: 0;
                }

                .nav-link:focus {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #4A55A2;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

                .nav-link.active {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #0642CD;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

              }

            }

            .course-desc {
              font-family: "Open Sans", sans-serif;
              font-size: 14px;
              font-weight: 400;
              color: #7185B8;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;


            }


          }
        }

        .contentWraper {
          height: 100%;
          display: flex;

          .postContent {
            padding-inline: 36px;
            width: 100%;
            height: 100%;
            display: flex;

            .tabWraper {
              width: 100%;
              height: 100%;

              .tab-pane {
                width: 100%;
                height: 100%;

                h1 {
                  font-family: "Open Sans", sans-serif;
                }
              }

              .posts {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                gap: 0 !important;

                .col {
                  max-width: 370px;
                  padding-inline: 7.5px !important;
                  padding-bottom: 15px !important;
                    justify-content: flex-start;
                }

                .cards {
                  max-height: 375px;
                  background-color: white;
                  padding: 0;
                  display: block;
                  margin: 0;
                  flex-wrap: wrap;
                  border: none;
                  text-decoration: none;
                  transition: 0.5s;
                  box-shadow: 0 0 15px #ececea;

                  img {
                    width: 100%;
                    height: 189px;
                  }

                  .playCase {
                    width: 100%;
                    min-height: 52px;
                    padding: 0 25px 0 0;
                    display: flex;
                    align-content: stretch;
                    background: transparent;
                    position: inherit;
                        justify-content: flex-end;
                    margin-top: -26px;

                    .play {
                      width: 52px;
                      height: 52px;
                      border-radius: 50px;
                      background: #FFF;
                      display: flex;
                      position: absolute;
                      justify-content: center;
                      align-items: center;
                      box-shadow: 0 2px 8px rgba(91, 109, 249, 0.2);

                      .playButton {
                        width: 100%;

                      }
                    }
                  }

                  .content {
                    margin-top: -26px;
                    height:190px; min-height: 190px;
                    padding-inline: 36px;
                    gap: 10px;

                    .course-title {
                      margin-top: 30px;
                      margin-bottom: 0;
                      font-family: "Open Sans", sans-serif;
                      font-size: 22px;
                      font-weight: 600;
                      color: #103F9F;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      transition: all 0.5s;
                    }

                    .course-desc {
                      padding-top: 16px;
                    }
                  }
                }

                .cards:hover {
                  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
                  transform: translateY(-15px);
                }
              }
            }
          }
        }
      }

      .pagination {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    hr {
      padding: 0;
      margin: 0;
      background-color: #D9D9D9;
      height: 1px;
    }

    h1, h2, h3, h4 {
      color: var(--Black-Text, #103F9F);
      font-family: "Open Sans", sans-serif;
    }

    h1 {
      padding: 0;
      margin: 0;
      color: var(--Black-Text, #103F9F);
      font-family: 'Open Sans', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .tab-pane {
    }

    h2 {
      padding: 0;
      margin: 0;
      font-size: 26px;
      font-weight: 600;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }

    h4 {
      padding: 15px 0 0 0;
      margin: 0;
      font-size: 18px;
      display: flex;
      width: 80px;
      word-wrap: break-word;
    }

    .course-desc {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #7185B8;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    nav {
      background: transparent !important;
      border: none !important;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #CAC6D4 !important;

      ul {
        background-color: transparent !important;
        gap: 30px;
        justify-content: center;
        align-items: center;

        li {
          background: transparent !important;
          border: none !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #CAC6D4 !important;

          a {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: #CAC6D4 !important;
            padding: 0 !important;
          }

          a.active {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: white !important;
            padding: 0 !important;
          }
        }

        li:active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        li.active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        a:focus {
          box-shadow: none !important;
        }
      }
    }
  }

}

@media only screen and (max-width: 425px) {

  .account {
    width: 100%;
    height: fit-content;
    background-color: #F9FAFF;
    display: flex;
    position: relative;
    justify-content: center;

    .decore {
      background-color: #103F9F;
      width: 100%;
      height: 201px;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      display: none;

      .dots-left {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
        display: none;
      }

      .dots-right {
        height: 100%;
        margin-top: 6px;
        width: fit-content;
        display: none;
      }
    }

    .accountContent {
      height: 100%;
      position: relative;
      background-color: transparent;
      display: flex;
      margin-top: 0;
      padding-inline: 36px;
      justify-content: center;
      width: 100%;
      border: none;
      z-index: 2;

      .wraper {
        height: 100%;
        position: relative;
        margin-top: 0;
        background-color: transparent;
        display: flex;
        justify-content: center;
        width: 100%;
        border: none;
        z-index: 2;

        .statsWraper {
          background-color: #fff;
          height: fit-content;
          width: 100%;

          .stats {
            background-color: #fff;
            display: block;
            height: fit-content;
            width: 100%;

            .stats-top {
              height: fit-content;
              display: block;

              .sections {
                display: flex;
                flex-direction: column;

                .title {
                  justify-content: center;
                  text-align: center;
                  align-items: center;
                  width: inherit;
                  margin-block: 36px;
                }

                .data-area {
                  width: 100%;
                  display: flex;
                  text-align: center;
                  margin-bottom: 32px;

                  .data-wraper {
                    width: 100%;
                    display: flex;
                    position: relative;
                    justify-content: space-evenly;
                    gap: 0;

                    .stats-data {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .langs {
              border: none;
              display: flex;
              height: 64px;
              align-items: center;
              padding: 0;
              margin: 0;

              .lang-tabs {
                padding-inline: 36px;
                height: 100%;
                display: flex;
                align-items: center;
                    justify-content: flex-end;
                width: 100%;

                nav {
                  padding: 0;
                  margin: 0;
                  gap: 0;
                  height: 100%;
                  display: flex;
                }

                .nav-tabs {
                  display: flex;
                  height: 100%;
                  padding: 0;
                  row-gap: 0;
                  column-gap: 20px;
                  margin: 0;
                  width: 100%;
                  align-items: center;
                  justify-content: space-evenly;
                  background: transparent !important;
                  border-bottom: 1px solid transparent !important;
                }

                .nav-tabs2 {
                  height: 59px;
                  width: 146px;
                 border:none!important;
                  gap: 9px;
                  display: flex;
                  position: relative;
                  justify-content: space-between;
                  align-items: center;

                  border-radius: 5px;


                  .dropdown {
                    .btn {

                      border: none;
                      font-size: 18px;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                      font-weight: 400;
                      padding-right: 0;
                      color: #103F9F;
                    }
                  }
                }

                .nav-tabs:hover {
                  background: transparent !important;
                  border: none;
                  height: 100%;
                  border-bottom: 1px solid black;
                }

                .select {
                  justify-self: end;
                  display: flex;
                  width: 180px;
                  height: 40px;
                  justify-content: center;
                  text-align: center;
                  border-radius: 5px;
                  font-family: "Open Sans", sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  border: 1px solid #928F9A;
                  color: #103F9F;
                  outline: none;
                }

                .nav-link {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid transparent;
                  border-spacing: 20px;
                  padding: 20px 0 10px 0;
                  border-radius: 0;
                  height: 100%;
                  font-size: 14px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  color: #7185B8;
                }

                .nav-link:hover {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #0642CD;
                  padding: 20px 0 10px;
                  height: 100%;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 400;
                  border-radius: 0;
                }

                .nav-link:focus {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #0642CD;
                  border-spacing: 20px;
                  color: #0642CD;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

                .nav-link.active {
                  background: transparent !important;
                  border: none;
                  border-bottom: 2px solid #4A55A2;
                  border-spacing: 20px;
                  color: #4A55A2;
                  height: 100%;
                  padding: 20px 0 10px;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 600;
                  border-radius: 0;
                }

              }

            }

            .course-desc {
              font-family: "Open Sans", sans-serif;
              font-size: 14px;
              font-weight: 400;
              color: #7185B8;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;


            }


          }
        }

        .contentWraper {
          height: 100%;
          display: flex;

          .postContent {
            width: 100%;
            height: 100%;
            display: flex;

            .tabWraper {
              width: 100%;
              height: 100%;

              .tab-pane {
                width: 100%;
                height: 100%;

                .tab-content {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 66px;
                  padding-bottom: 48px;

                  .tab-heading {
                    padding-top: 32px;
                    padding-bottom: 20px;
                  }

                  .tab-description {
                    height: fit-content;
                    width: 100%;
                    margin-top: 20px;
                    border: none;
                    gap: 9px;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;

                    .btn {
                      background-color: transparent !important;
                      border: 1px solid #103F9F;
                      font-size: 18px;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                      font-weight: 400;
                      padding-block: 15px;
                      padding-inline: 16px;
                      transition: 0.5s;
                      box-shadow: 0 0 15px #ececea;
                      color: #103F9F;

                      p {
                        color: #103F9F;
                        fill: #103F9F;
                        gap: 9px;
                      }
                    }

                    .dropdown-toggle {
                      background-color: transparent !important;
                      border: 1px solid #103F9F;
                      width: 100%;
                      color: #103F9F !important;
                      font-weight: 400;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                    }

                    .dropdown-toggle:hover {
                      background-color: #0642CD !important;
                      border: 1px solid transparent;
                      color: #0642CD;
                      font-weight: 400;
                      text-transform: capitalize;
                      font-family: "Open Sans", "sans-serif";
                      transition: all 0.5s;
                      box-shadow: 0 10px 20px rgba(43, 52, 132, 0.25);

                      p {
                        color: white;
                        fill: white;
                        font-family: "Open Sans", "sans-serif";
                        gap: 9px;
                        font-weight: 400;
                        box-shadow: none;
                        background-color: transparent !important;
                        border: none;
                      }
                    }
                  }

                  .dropdown-menu {
                    justify-content: center;
                    background-color: white !important;
                    box-shadow: none;

                    .dropdown-item {
                      color: #2B3484;
                      padding: 0;
                    }

                    .dropdown-item:focus, :active {
                      background-color: white !important;
                      box-shadow: none !important;
                      border: none !important;
                    }

                    p {
                      color: #2B3484;
                    }
                  }

                  .dropdown-menu:active, :focus {
                    background-color: white !important;
                    box-shadow: none;
                    border: none;

                    .dropdown-item {
                      color: #2B3484;
                    }

                    p {
                      color: #2B3484;
                    }
                  }
                }

                h1 {
                  font-family: "Open Sans", sans-serif;
                }
              }

              .posts {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                gap: 0 !important;

                .col {
                  max-width: 370px;
                  padding-inline: 0 !important;
                  padding-bottom: 30px !important;
                    justify-content: flex-start;
                }

                .cards {
                  max-height: 353px;
                  background-color: white;
                  padding: 0;
                  display: block;
                  margin: 0;
                  flex-wrap: wrap;
                  border: none;
                  text-decoration: none;
                  transition: 0.5s;
                  box-shadow: 0 0 15px #ececea;

                  img {
                    width: 100%;
                    height: 189px;
                  }

                  .playCase {
                    width: 100%;
                    min-height: 52px;
                    padding: 0 25px 0 0;
                    display: flex;
                    align-content: stretch;
                    background: transparent;
                    position: inherit;
                        justify-content: flex-end;
                    margin-top: -26px;

                    .play {
                      width: 52px;
                      height: 52px;
                      border-radius: 50px;
                      background: #FFF;
                      display: flex;
                      position: absolute;
                      justify-content: center;
                      align-items: center;
                      box-shadow: 0 2px 8px rgba(91, 109, 249, 0.2);

                      .playButton {
                        width: 100%;

                      }
                    }
                  }

                  .content {
                    margin-top: -26px;
                    height:190px; min-height: 190px;
                    padding-inline: 36px;
                    gap: 10px;

                    .course-title {
                      margin-top: 30px;
                      margin-bottom: 0;
                      font-family: "Open Sans", sans-serif;
                      font-size: 22px;
                      font-weight: 600;
                      color: #103F9F;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      transition: all 0.5s;
                    }

                    .course-desc {
                      padding-top: 16px;
                    }
                  }
                }

                .cards:hover {
                  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
                  transform: translateY(-15px);
                }
              }
            }
          }
        }
      }

      .pagination {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    hr {
      padding: 0;
      margin: 0;
      background-color: #D9D9D9;
      height: 1px;
    }

    h1, h2, h3, h4 {
      color: var(--Black-Text, #103F9F);
      font-family: "Open Sans", sans-serif;
    }

    h1 {
      padding: 0;
      margin: 0;
      color: var(--Black-Text, #103F9F);
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .tab-pane {
    }

    h2 {
      padding: 0;
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }

    h4 {
      padding: 15px 0 0 0;
      margin: 0;
      font-size: 14px;
      display: flex;
      width: 80px;
      word-wrap: break-word;
    }

    .course-desc {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #7185B8;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    nav {
      background: transparent !important;
      border: none !important;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #CAC6D4 !important;

      ul {
        background-color: transparent !important;
        gap: 30px;
        justify-content: center;
        align-items: center;

        li {
          background: transparent !important;
          border: none !important;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #CAC6D4 !important;

          a {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: #CAC6D4 !important;
            padding: 0 !important;
          }

          a.active {
            background: transparent !important;
            border: none !important;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            margin: 0 !important;
            color: white !important;
            padding: 0 !important;
          }
        }

        li:active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        li.active {
          background-color: #103F9F !important;
          display: flex;
          width: 32px;
          height: 32px;
          margin: 0;
          color: white !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
        }

        a:focus {
          box-shadow: none !important;
        }
      }
    }
  }

}
.pagination{
  border:none!important;

}
.page-link {

    border:none!important;
    background: transparent;
  border-radius: 4px;
  margin:30px;


  color:#fff!important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pagination .page-item .page-link{
  color:#7185B8!important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.page-item.active .page-link{
  background-color: #0642CD;
  color:#fff!important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.page-item:last-child .page-link{
  background: #D5ECFF;
}
.page-item.disabled .page-link{
  background: #D5ECFF;
}

@media only screen and (max-width: 475px) {
  .drop-tog {
    background-color: transparent!important;
    border:1px solid #103F9F;
    background: transparent!important;
    width: 120%;
    height: 40px;
    color: var(--Main-Color, #103F9F);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .drop-tog:focus, .drop-tog:hover{
    box-shadow: none!important;
    outline: none!important;
  }
  .drop{
    border:none!important;
  }
}

.mobile-user-name{
  color: var(--Black-Text, #103F9F);

  /* Paragraf 2 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-desc-level{
  color: var(--Soft-Text, #7185B8);

  /* Paragraf 3 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
