.quiz {
  width: 100%;
  height: 100vh;
  display: flex;
  accent-color: white!important;

  .courseList {
    display: flex;
    flex-direction: column;
    min-width: 450px;
    height: 100%;
    padding-bottom: 50px;

    .nav-tabs {
      display: flex;
      gap: 32px;
      height: 65px;
      padding: 0 20px;
      margin: 0;
      align-items: center;
      background: transparent !important;
      border-bottom: 1px solid #EFEFF6 !important;
    }

    .nav-tabs:hover {
      background: transparent !important;
      border: none;
      height: 100%;
      border-bottom: 1px solid black;
    }

    .nav-link {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid transparent;
      border-spacing: 20px;
      padding: 20px 0 10px 0;
      border-radius: 0;
      height: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      color: #928F9A;
    }

    .nav-link:hover {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #4A55A2;
      border-spacing: 20px;
      color: #4A55A2;
      padding: 20px 0 10px;
      height: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      border-radius: 0;
    }

    .nav-link:focus {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #4A55A2;
      border-spacing: 20px;
      color: #4A55A2;
      height: 100%;
      padding: 20px 0 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      border-radius: 0;
    }

    .nav-link.active {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #103F9F;
      border-spacing: 20px;
      color: #103F9F;
      height: 100%;
      padding: 20px 0 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      border-radius: 0;
    }

    .contentHeader {
      background-color: #103F9F;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;

      .header-top {
        margin-inline: 48px;
        margin-top: 40px;
        margin-bottom: 47px;

        .turnBack {
          display: flex;
          align-items: center;
          text-decoration: none !important;
          gap: 19px;

          .backButton {
            background-color: #6e77b5;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 100%;

            .leftArrow {
              opacity: 100%;
              fill: white;
            }
          }

          .backText {
            padding: 0;
            margin: 0;
            color: white !important;
            font-weight: 400;
            font-size: 18px;
            font-family: "Open Sans", sans-serif;
          }
        }

        .Title {
          margin-top: 42px;
          padding: 0;
          margin-bottom: 0;
          color: white;
          font-size: 40px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
        }
      }

      .progressComp {
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 40px;
        background-color: transparent;

        .percent {
          text-align: center;
          padding: 0;
          margin-bottom: 9px;
          font-size: 16px;
          color: white;
          font-weight: 400;
          font-family: 'Open Sans', sans-serif;
        }

        .progressBar {
          background-color: #43C8B1;
          width: 67%;
          min-height: 10px;
        }
      }
    }

    .searchField {
      width: 100%;
      padding-inline: 48px;
      display: flex;
        justify-content: flex-start;
      align-items: center;
      height: auto;
      background-color: #EFF3FD;
      gap: 9px;

      .search {
        border: none;
        padding: 0;
        height: 70px;
        margin: 0;
        color: #302D3A;
        width: 100%;
        opacity: 50%;
        font-size: 16px;
        font-weight: 400;
        outline: none;
        font-family: "Open Sans", sans-serif;
        background-color: transparent;
      }

      ::placeholder {
        color: #928F9A;
        width: 100%;
        opacity: 50%;
      }
    }

    .lessonList {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-block: 36px;
      padding-inline: 50px;
      width: 100%;
      height: 100%;
      background-color: transparent;

      p {
        padding: 0;
        margin: 0;
      }

      .lesson {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 50px;

        .Title {
          font-size: 22px;
          padding-bottom: 15px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
          color: #0642CD;
        }

        .lessonDetail:active {
          padding-inline: 12px;
          width: 100%;
          height: 63px;
          display: flex;
          align-items: center;
          background-color: #43C8B1;
          color: white;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;

          .desc {
            margin-inline-end: 35px;
            width: 204px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .during {
            padding-inline: 12px;
            padding-block: 3px;
            background-color: rgba(255, 255, 255, 0.145);
            font-size: 12px;
            color: white;
            border-radius: 2px;
            font-weight: 400;
          }

          .Title {
            width: 100%;
          }

          .book {
            margin-inline-end: 10px;
            fill: white;
          }
        }

        .lessonDetail {
          padding-inline: 12px;
          width: 100%;
          height: 63px;
          display: flex;
          align-items: center;
          background-color: #EDF3FF;
          color: #302D3A;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;

          .desc {
            margin-inline-end: 35px;
            width: 204px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .during {
            padding-inline: 12px;
            padding-block: 3px;
            background-color: rgba(91, 109, 249, 0.145);
            font-size: 12px;
            color: #0642CD;
            border-radius: 2px;
            font-weight: 400;
          }

          .book {
            margin-inline-end: 10px;
            fill: #4A55A2;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding-inline: 50px;

    .head {
      display: flex;
      width: 100%;
      min-height: 120px;
      max-height: 120px;
      justify-content: space-between;
      align-items: center;

      .texts {
        display: flex;
        align-items: center;

        .Title {
          font-size: 40px;
          font-weight: 600;
          padding: 0;
          margin: 0;
          font-family: "Open Sans", sans-serif;
          color: #0642CD;
        }

        .during {
          margin: 0 0 0 36px;
          max-width: 90px;
          max-height: 40px;
          width: auto;
          height: auto;
          padding-block: 7px;
          padding-inline: 20px;
          background: #FEEFEE;
          font-family: "Open Sans", sans-serif;
          color: #F83B69;
          justify-content: center;
          font-size: 13px;
          font-weight: 400;
          border-radius: 5px;
          align-items: center;
          display: flex;
        }
      }

      .nextLesson {
        background-color: #0642CD;
        border: none;
        max-height: 57px;
        height: auto;
        max-width: 179px;
        width: auto;
        padding-inline: 32px;
        padding-block: 16px;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        font-weight: 600;
        box-shadow: none;
      }
    }

    .exam {
   /*   display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
      width: 100%;*/

      .headerInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: "Open Sans", sans-serif;

        .Title{
          font-size: 36px;
          font-weight: 600;
          padding: 0;
          margin: 0;
          font-family: "Open Sans", sans-serif;
          color: #103F9F;
        }
        .Description{
          font-size: 16px;
          font-weight: 400;
          color: #928F9A;
          margin-top: 32px;
          line-height: 32px;
          margin-bottom: 0;
          padding: 0;
        }
      }
      .info{
        width: 100%;
        margin-top: 96px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        color: #FC196E;
        background-color: #FFF1F3;
        height: 70px;
        border-radius: 5px;
        border: 1px dashed #FC196E;

        .questionNumber{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .seperator{
          border: 1px solid #FC196E;
          width: 0.5px;
          height: 30px;
          opacity: 75%;
        }
        .time{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .question{
        background-color: #F1F3FD;
        margin-top: 41px;
        padding: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .questionDesc{
          font-size: 24px;
          font-weight: 400;
          color: #103F9F;
          margin-bottom: 25px;
        }
        .ansfers{
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 0;
          font-size: 22px;
          font-weight: 600;
          color: #928F9A;
          list-style: none;
          .choise{
            padding-block: 15px;
            border: 1px solid #CAC6D4;
            background-color: transparent;
            display: flex;
              justify-content: flex-start;
            outline: none;
            box-shadow: none;
            font-size: 22px;
            font-weight: 600;
            color: #928F9A;
            border-radius: 5px;
            padding-inline: 30px
          }
          .choise:active, :focus{

            background-color: #4A55A2;
            .choice{
              border: 1px solid transparent;
              background-color: transparent;
              display: flex;
                justify-content: flex-start;
              outline: none;
              box-shadow: none;
              font-size: 22px;
              font-weight: 600;
              color: white;

            }
          }
          .choice{
            border: 1px solid transparent;
            background-color: transparent;
            display: flex;
              justify-content: flex-start;
            outline: none;
            box-shadow: none;
            font-size: 22px;
            font-weight: 600;
            color: #928F9A;

          }
        }
      }
      .navButtons{
        width: 100%;
        display: flex;
        margin-top: 52px;
        justify-content: space-between;
        button{
          border: none;
          padding-inline: 28px;
          padding-block: 14px;
        }
        .prev{
          color: #0642CD;
          background-color: #DEE9FF;
        }
        .next{
          color: #FFFFFF;
          background-color: #FC196E;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .quiz {
    width: 100%;
    height: 100vh;
    display: flex;
    accent-color: white!important;

    .courseList {
      display: flex;
      flex-direction: column;
      min-width: 450px;
      height: 100%;
      padding-bottom: 50px;

      .nav-tabs {
        display: flex;
        gap: 32px;
        height: 65px;
        padding: 0 20px;
        margin: 0;
        align-items: center;
        background: transparent !important;
        border-bottom: 1px solid #EFEFF6 !important;
      }

      .nav-tabs:hover {
        background: transparent !important;
        border: none;
        height: 100%;
        border-bottom: 1px solid black;
      }

      .nav-link {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid transparent;
        border-spacing: 20px;
        padding: 20px 0 10px 0;
        border-radius: 0;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #928F9A;
      }

      .nav-link:hover {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        padding: 20px 0 10px;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        border-radius: 0;
      }

      .nav-link:focus {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .nav-link.active {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .contentHeader {
        background-color: #4A55A2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;

        .header-top {
          margin-inline: 48px;
          margin-top: 40px;
          margin-bottom: 47px;

          .turnBack {
            display: flex;
            align-items: center;
            text-decoration: none !important;
            gap: 19px;

            .backButton {
              background-color: #6e77b5;
              display: flex;
              position: relative;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              border-radius: 100%;

              .leftArrow {
                opacity: 100%;
                fill: white;
              }
            }

            .backText {
              padding: 0;
              margin: 0;
              color: white !important;
              font-weight: 400;
              font-size: 18px;
              font-family: "Open Sans", sans-serif;
            }
          }

          .Title {
            margin-top: 42px;
            padding: 0;
            margin-bottom: 0;
            color: white;
            font-size: 40px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
          }
        }

        .progressComp {
          justify-content: center;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40px;
          background-color: transparent;

          .percent {
            text-align: center;
            padding: 0;
            margin-bottom: 9px;
            font-size: 16px;
            color: white;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
          }

          .progressBar {
            background-color: #43C8B1;
            width: 67%;
            min-height: 10px;
          }
        }
      }

      .searchField {
        width: 100%;
        padding-inline: 48px;
        display: flex;
          justify-content: flex-start;
        align-items: center;
        height: auto;
        background-color: #EFF3FD;
        gap: 9px;

        .search {
          border: none;
          padding: 0;
          height: 70px;
          margin: 0;
          color: #302D3A;
          width: 100%;
          opacity: 50%;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          font-family: "Open Sans", sans-serif;
          background-color: transparent;
        }

        ::placeholder {
          color: #928F9A;
          width: 100%;
          opacity: 50%;
        }
      }

      .lessonList {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-block: 36px;
        padding-inline: 50px;
        width: 100%;
        height: 100%;
        background-color: transparent;

        p {
          padding: 0;
          margin: 0;
        }

        .lesson {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-bottom: 50px;

          .Title {
            font-size: 22px;
            padding-bottom: 15px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .lessonDetail:active {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #43C8B1;
            color: white;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(255, 255, 255, 0.145);
              font-size: 12px;
              color: white;
              border-radius: 2px;
              font-weight: 400;
            }

            .Title {
              width: 100%;
            }

            .book {
              margin-inline-end: 10px;
              fill: white;
            }
          }

          .lessonDetail {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #EDF3FF;
            color: #302D3A;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(91, 109, 249, 0.145);
              font-size: 12px;
              color: #0642CD;
              border-radius: 2px;
              font-weight: 400;
            }

            .book {
              margin-inline-end: 10px;
              fill: #4A55A2;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding-inline: 50px;

      .head {
        display: flex;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        justify-content: space-between;
        align-items: center;

        .texts {
          display: flex;
          align-items: center;

          .Title {
            font-size: 32px;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .during {
            margin: 0 0 0 36px;
            max-width: 90px;
            max-height: 40px;
            width: auto;
            height: auto;
            padding-block: 7px;
            padding-inline: 20px;
            background: #FEEFEE;
            font-family: "Open Sans", sans-serif;
            color: #F83B69;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            border-radius: 5px;
            align-items: center;
            display: flex;
          }
        }

        .nextLesson {
          background-color: #0642CD;
          border: none;
          max-height: 57px;
          height: auto;
          max-width: 179px;
          width: auto;
          padding-inline: 32px;
          padding-block: 16px;
          font-family: "Open Sans", sans-serif;
          font-size: 15px;
          font-weight: 600;
          box-shadow: none;
        }
      }


    }
  }
}

.exam {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;*/


  .headerInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Open Sans", sans-serif;

    .Title{
      font-size: 32px;
      font-weight: 600;
      padding: 0;
      margin: 0;
      font-family: "Open Sans", sans-serif;
      color: #0642CD;
    }
    .Description{
      color: var(--Soft-Text, #7185B8);
      font-family: "Poppins",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7rem;
    }
  }
  .info{
    width: 100%;
    margin-top: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    color: #FC196E;
    background-color: #FFF1F3;
    height: 70px;
    border-radius: 5px;
    border: 1px dashed #FC196E;

    .questionNumber{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .seperator{
      border: 1px solid #FC196E;
      width: 0.5px;
      height: 30px;
      opacity: 75%;
    }
    .time{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .question{
    background-color: #F6F8FF;
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .questionDesc{
      font-size: 24px;
      font-weight: 400;
      color: #103F9F;
      margin-bottom: 25px;
    }
    .ansfers{
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 0;
      font-size: 22px;
      font-weight: 600;
      color: #928F9A;
      list-style: none;
      .choise{
        padding-block: 15px;
        border: 1px solid #CAC6D4;
        background-color: transparent;
        display: flex;
          justify-content: flex-start;
        outline: none;
        box-shadow: none;
        font-size: 22px;
        font-weight: 600;
        color: #928F9A;
        border-radius: 5px;
        padding-inline: 30px
      }
      .choise:active, :focus{

        background-color: #4A55A2;
        .choice{
          border: 1px solid transparent;
          background-color: transparent;
          display: flex;
            justify-content: flex-start;
          outline: none;
          box-shadow: none;
          font-size: 22px;
          font-weight: 600;
          color: white;

        }
      }
      .choice{
        border: 1px solid transparent;
        background-color: transparent;
        display: flex;
          justify-content: flex-start;
        outline: none;
        box-shadow: none;
        font-size: 22px;
        font-weight: 600;
        color: #928F9A;

      }
    }
  }
  .navButtons{
    width: 100%;
    display: flex;
    margin-top: 52px;
    justify-content: space-between;
    button{
      border: none;
      padding-inline: 28px;
      padding-block: 14px;
    }
    .prev{
      color: #0642CD;
      background-color: #DEE9FF;
    }
    .next{
      color: #FFFFFF;
      background-color: #FC196E;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .quiz {
    width: 100%;
    height: 100vh;
    display: flex;
    accent-color: white!important;

    .courseList {
      display: flex;
      flex-direction: column;
      min-width: 450px;
      height: 100%;
      padding-bottom: 50px;

      .nav-tabs {
        display: flex;
        gap: 32px;
        height: 65px;
        padding: 0 20px;
        margin: 0;
        align-items: center;
        background: transparent !important;
        border-bottom: 1px solid #EFEFF6 !important;
      }

      .nav-tabs:hover {
        background: transparent !important;
        border: none;
        height: 100%;
        border-bottom: 1px solid black;
      }

      .nav-link {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid transparent;
        border-spacing: 20px;
        padding: 20px 0 10px 0;
        border-radius: 0;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #928F9A;
      }

      .nav-link:hover {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        padding: 20px 0 10px;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        border-radius: 0;
      }

      .nav-link:focus {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .nav-link.active {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .contentHeader {
        background-color: #4A55A2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;

        .header-top {
          margin-inline: 48px;
          margin-top: 40px;
          margin-bottom: 47px;

          .turnBack {
            display: flex;
            align-items: center;
            text-decoration: none !important;
            gap: 19px;

            .backButton {
              background-color: #6e77b5;
              display: flex;
              position: relative;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              border-radius: 100%;

              .leftArrow {
                opacity: 100%;
                fill: white;
              }
            }

            .backText {
              padding: 0;
              margin: 0;
              color: white !important;
              font-weight: 400;
              font-size: 18px;
              font-family: "Open Sans", sans-serif;
            }
          }

          .Title {
            margin-top: 42px;
            padding: 0;
            margin-bottom: 0;
            color: white;
            font-size: 26px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
          }
        }

        .progressComp {
          justify-content: center;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40px;
          background-color: transparent;

          .percent {
            text-align: center;
            padding: 0;
            margin-bottom: 9px;
            font-size: 16px;
            color: white;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
          }

          .progressBar {
            background-color: #43C8B1;
            width: 100%;
            min-height: 10px;
          }
        }
      }

      .searchField {
        width: 100%;
        padding-inline: 48px;
        display: flex;
          justify-content: flex-start;
        align-items: center;
        height: auto;
        background-color: #EFF3FD;
        gap: 9px;

        .search {
          border: none;
          padding: 0;
          height: 70px;
          margin: 0;
          color: #302D3A;
          width: 100%;
          opacity: 50%;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          font-family: "Open Sans", sans-serif;
          background-color: transparent;
        }

        ::placeholder {
          color: #928F9A;
          width: 100%;
          opacity: 50%;
        }
      }

      .lessonList {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-block: 36px;
        padding-inline: 50px;
        width: 100%;
        height: 100%;
        background-color: transparent;

        p {
          padding: 0;
          margin: 0;
        }

        .lesson {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-bottom: 50px;

          .Title {
            font-size: 30px;
            padding-bottom: 15px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .lessonDetail:active {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #43C8B1;
            color: white;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(255, 255, 255, 0.145);
              font-size: 12px;
              color: white;
              border-radius: 2px;
              font-weight: 400;
            }

            .Title {
              width: 100%;
            }

            .book {
              margin-inline-end: 10px;
              fill: white;
            }
          }

          .lessonDetail {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #EDF3FF;
            color: #302D3A;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(91, 109, 249, 0.145);
              font-size: 12px;
              color: #0642CD;
              border-radius: 2px;
              font-weight: 400;
            }

            .book {
              margin-inline-end: 10px;
              fill: #4A55A2;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding-inline: 50px;

      .head {
        display: flex;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        justify-content: space-between;
        align-items: center;

        .texts {
          display: flex;
          align-items: center;

          .Title {
            font-size: 24px;
            font-weight: 600;
            width: fit-content !important;
            height: fit-content !important;
            padding: 0;
            margin: 0;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .during {
            margin: 0 10px;
            max-width: 90px;
            max-height: 40px;
            width: auto;
            height: auto;
            padding-block: 3px;
            padding-inline: 10px;
            background: #FEEFEE;
            font-family: "Open Sans", sans-serif;
            color: #F83B69;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            border-radius: 5px;
            align-items: center;
            display: flex;
          }
        }

        .nextLesson {
          background-color: #0642CD;
          border: none;
          max-height: 57px;
          height: auto;
          max-width: 179px;
          width: auto;
          padding-inline: 16px;
          padding-block: 12px;
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
          font-weight: 600;
          box-shadow: none;
        }
      }


    }
  }

  .exam {
/*    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;*/


    .headerInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-family: "Open Sans", sans-serif;

      .Title{
        font-size: 40px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        font-family: "Open Sans", sans-serif;
        color: #0642CD;
      }
      .Description{
        font-size: 16px;
        font-weight: 400;
        color: #928F9A;
        margin-top: 32px;
        line-height: 32px;
        margin-bottom: 0;
        padding: 0;
      }
    }
    .info{
      width: 100%;
      margin-top: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 22px;
      font-weight: 600;
      color: #FC196E;
      background-color: #FFF1F3;
      height: 70px;
      border-radius: 5px;
      border: 1px dashed #FC196E;

      .questionNumber{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .seperator{
        border: 1px solid #FC196E;
        width: 0.5px;
        height: 30px;
        opacity: 75%;
      }
      .time{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .question{
      background-color: #F1F3FD;
      margin-top: 41px;
      padding: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .questionDesc{
        font-size: 24px;
        font-weight: 400;
        color: #103F9F;
        margin-bottom: 25px;
      }
      .ansfers{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0;
        font-size: 22px;
        font-weight: 600;
        color: #928F9A;
        list-style: none;
        .choise{
          padding-block: 15px;
          border: 1px solid #CAC6D4;
          background-color: transparent;
          display: flex;
            justify-content: flex-start;
          outline: none;
          box-shadow: none;
          font-size: 22px;
          font-weight: 600;
          color: #928F9A;
          border-radius: 5px;
          padding-inline: 30px
        }
        .choise:active, :focus{

          background-color: #4A55A2;
          .choice{
            border: 1px solid transparent;
            background-color: transparent;
            display: flex;
              justify-content: flex-start;
            outline: none;
            box-shadow: none;
            font-size: 22px;
            font-weight: 600;
            color: white;

          }
        }
        .choice{
          border: 1px solid transparent;
          background-color: transparent;
          display: flex;
            justify-content: flex-start;
          outline: none;
          box-shadow: none;
          font-size: 22px;
          font-weight: 600;
          color: #928F9A;

        }
      }
    }
    .navButtons{
      width: 100%;
      display: flex;
      margin-top: 52px;
      justify-content: space-between;
      button{
        border: none;
        padding-inline: 28px;
        padding-block: 14px;
      }
      .prev{
        color: #0642CD;
        background-color: #DEE9FF;
      }
      .next{
        color: #FFFFFF;
        background-color: #FC196E;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .quiz {
    width: auto;
    height: 100vh;
    display: flex;

    .mobile {
      display: flex;
      flex-direction: column;
      width: auto;
      height: 100%;
      user-select: none;

      .contentHeader {
        background-color: #4A55A2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;

        .header-top {
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 24px;

          .turnBack {
            display: flex;
            align-items: center;
            text-decoration: none !important;

            .backButton {
              background-color: #6e77b5;
              display: flex;
              position: relative;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              border-radius: 100%;

              .leftArrow {
                opacity: 100%;
                fill: white;
              }
            }

            .backText {
              padding: 0;
              margin: 0;
              color: white !important;
              font-weight: 400;
              font-size: 18px;
              font-family: "Open Sans", sans-serif;
            }
          }

          .Title {
            margin-top: 21px;
            color: white;
            font-size: 24px;
            margin-bottom: 27px;
            font-weight: 600;
            margin-left: 4px;
            font-family: "Open Sans", sans-serif;
          }
        }

        .progressComp {
              justify-content: flex-end;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40px;
          background-color: transparent;

          .percent {
            text-align: center;
            padding: 0;
            margin-bottom: 9px;
            font-size: 16px;
            color: white;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
          }

          .progressBar {
            background-color: #43C8B1;
            width: 67%;
            min-height: 10px;
          }
        }
      }

      .padding{
        padding-inline: 20px;
        padding-bottom: 36px;
        .info{
          width: 100%;
          margin-top: 24px;
          display: flex;
          height: 70px;
          justify-content: space-between;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
          color: #FC196E;
          background-color: #FFF1F3;
          border-radius: 5px;
          border: 1px dashed #FC196E;

          .questionNumber{
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .seperator{
            border: 1px solid #FC196E;
            width: 0.5px;
            height: 30px;
            opacity: 75%;
          }
          .time{
            width: 100%;
            display: flex;
            height: 70px;
            justify-content: center;
            align-items: center;
          }
        }
        .question{
          background-color: #F1F3FD;
          margin-top: 41px;
          padding: 24px 20px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .next{
            font-size: 16px;
            font-weight: 600;
            padding-inline: 24px;
            padding-block: 8px;
            background-color: #FC196E;
            border: none;
            width: 50%;
            align-self: end;
            margin-top: 17.32px;
          }
          .questionDesc{
            font-size: 22px;
            font-weight: 600;
            color: #103F9F;
            margin-bottom: 25px;
          }
          .ansfers{
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: #928F9A;
            list-style: none;
            .choise{
              padding-block: 20px;
              border: 1px solid #CAC6D4;
              background-color: transparent;
              display: flex;
                justify-content: flex-start;
              outline: none;
              box-shadow: none;
              font-size: 16px;
              font-weight: 400;
              color: #928F9A;
              border-radius: 5px;
              padding-inline: 0;
            }
            .choise:active, :focus{

              background-color: #4A55A2;
              .choice{
                border: 1px solid transparent;
                background-color: transparent;
                display: flex;
                  justify-content: flex-start;
                outline: none;
                box-shadow: none;
                font-size: 16px;
                font-weight: 400;
                color: white;
                padding-inline: 20px

              }
            }
            .choice{
              border: 1px solid transparent;
              background-color: transparent;
              display: flex;
                justify-content: flex-start;
              outline: none;
              box-shadow: none;
              font-size: 16px;
              font-weight: 400;
              padding-inline: 20px;
              color: #928F9A;

            }
          }
        }
        .navButtons{
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          justify-content: space-between;
          gap: 20px;
          button{
            border: none;
            padding-inline: 50px;
            padding-block: 20px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
          }
          .prev{
            color: #0642CD;
            background-color: #DEE9FF;
          }
          .nextQuiz{
            color: #FFFFFF;
            background-color: #0642CD;
          }
        }
      }

    }
  }
}

.quiz .courseList .lessonList .lesson .lessonDetail:nth-child(2n-1) {
  padding-inline: 12px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  background-color: #FFF1F3;
  color: #302D3A;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    background-color: rgba(254, 110, 142, 0.145);
    font-size: 12px;
    color: #F83B69;
    border-radius: 2px;
    font-weight: 400;
  }

  .book {
    margin-inline-end: 10px;
    fill: #FC196E;
  }
}

.quiz .courseList .lessonList .lesson .lessonDetail:nth-child(2n-1):active {
  padding-inline: 12px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  background-color: #43C8B1;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    background-color: rgba(255, 255, 255, 0.145);
    font-size: 12px;
    color: white;
    border-radius: 2px;
    font-weight: 400;
  }

  .Title {
    width: 100%;
  }

  .book {
    margin-inline-end: 10px;
    fill: white;
  }
}

.quiz .mobile .lessonList .lesson .lessonDetail:nth-child(2n-1) {
  padding-inline: 12px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  background-color: #FFF1F3;
  color: #302D3A;
  user-select: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  transition: 0.2s all ease-out;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    width: 65px;
    background-color: rgba(254, 110, 142, 0.145);
    font-size: 12px;
    color: #F83B69;
    border-radius: 2px;
    font-weight: 400;
  }

  .book {
    margin-inline-end: 10px;
    fill: #FC196E;
  }
}

.quiz .mobile .lessonList .lesson .lessonDetail:nth-child(2n-1):active {
  padding-inline: 12px;
  width: 65px;
  transition: 0.2s all ease-in;
  display: flex;
  align-items: center;
  background-color: #43C8B1;
  color: white;
  user-select: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    background-color: rgba(255, 255, 255, 0.145);
    font-size: 12px;
    color: white;
    border-radius: 2px;
    font-weight: 400;
  }

  .Title {
    width: 100%;
  }

  .book {
    margin-inline-end: 10px;
    fill: white;
  }
}
.exam-next-btn{
  background: #FC196E;
  border: none;
  border-radius: 4px;
  padding: 12px 25px 0px 25px!important;
  font-size: 18px;
  transition: 0.2s;
  font-weight: 500;
  font-family: "Poppins",sans-serif;
}.exam-prev-btn{
   background: #D5ECFF;
   border: none;
   border-radius: 4px;
   padding: 12px 25px 0px 25px!important;
   font-size: 18px;
   transition: 0.2s;
   font-weight: 500;
   font-family: "Poppins",sans-serif;
 }
.exam-prev-btn-text{
  color:  #103F9F!important;
  font-family: "Poppins",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.exam-next-btn-text{
  color: #FFF;
  font-family: "Poppins",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.exam-submit-btn{
  background: #103F9F;
  border: none;
  border-radius: 4px;
  padding: 12px 25px 0px 25px!important;
  font-size: 18px;
  transition: 0.2s;
  font-weight: 500;
  font-family: "Poppins",sans-serif;
}
.exam-next-btn-text{
  color: #FFF;
  font-family: "Poppins",sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

