#slider {
    min-height: 100%;
}

html,
body {
    font-family: 'Open Sans', sans-serif;
    position: relative;
    min-height: 100%;
}

.lowerRowContent .lowerRow2:nth-child(1) .levelTag, .lowerRow1:nth-child(1) .levelTag {
    background-color: #A5C1FF !important;
}

.lowerRowContent .lowerRow2:nth-child(2) .levelTag, .lowerRow1:nth-child(2) .levelTag {
    background-color: #5E76F6 !important;
}

.lowerRowContent .lowerRow2:nth-child(3) .levelTag, .lowerRow1:nth-child(3) .levelTag {
    background-color: #414FEA !important;
}

.lowerRowContent .lowerRow2:nth-child(4) .levelTag, .lowerRow1:nth-child(4) .levelTag {
    background-color: #333DCF !important;
}

.lowerRowContent .lowerRow2:nth-child(5) .levelTag, .lowerRow1:nth-child(5) .levelTag {
    background-color: #2C35A7 !important;
}

.lowerRowContent .lowerRow2:nth-child(6) .levelTag, .lowerRow1:nth-child(6) .levelTag {
    background-color: #2B3484 !important;
}

.swiper {
    min-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    transition: all;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: flex;
    max-width: 670px;
    width: 100%;
    max-height: 357px;
}

.swiper-pagination {
    align-items: center;
    height: 10px;
    gap: 10px;
    justify-content: center;
    display: flex;

}

.swiper-pagination-bullet {
    gap: 20px;
    width: 32px;
    height: 4px;
    border-radius: 20px;
    background-color: #fff;
    opacity: 1;

}

.swiper-pagination-bullet-active {
    background-color: #0642CD;
    width: 82px;
    height: 4px;
    border-radius: 20px;
    opacity: 100%;
}


.row {
    transition: all;
    gap: 20px;
}

.row .lowerRow1 {
    transition: all;
    width: 48%;
    max-width: 242px;
    height: 63px;
    border-radius: 20px;
    flex-shrink: 0;
    border: 1.5px solid #A3C2FE;
    z-index: 2;
    background: transparent;
}

.row .lowerRow2 {
    transition: all;
    width: 30%;
    max-width: 242px;
    height: 63px;
    border-radius: 20px;
    flex-shrink: 0;
    border: 1.5px solid #A3C2FE;
    z-index: 2;
    background: transparent;
}

.row .lowerRow1 .levelTag,
.row .lowerRow2 .levelTag {
    width: 39px;
    height: 39px;
    border-radius: 20px;
    flex-shrink: 0;
    margin-inline-start: 16px;
    margin-inline-end: 8px;
    font-size: 18px;
    font-weight: 600;
}

.row .lowerRow1 .cardText,
.row .lowerRow2 .cardText {
    margin-block: 12px;
    margin-inline-end: 20px;
}

.row .lowerRow1 .cardText .text-head,
.row .lowerRow2 .cardText .text-head {
    font-size: 14px;
    font-weight: 600;
    color: #103F9F;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.5s;
}

.row .lowerRow1 .cardText .text-inf,
.row .lowerRow2 .cardText .text-inf {
    font-size: 13px;
    font-weight: 400;
    color: #4E66BB;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.5s;
}

.banner-brief {
    margin-bottom: 120px;
}

@media only screen and (max-width: 1920px) { {
    max-width: 100%;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 24px;
}

    .row .lowerRow2 {
        width: 31% !important;
    }

    .banner-brief {
        margin-bottom: 50px;
    }

}

@media only screen and (max-width: 1400px) { {
    max-width: 100%;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 24px;
}

    .row .lowerRow2 {
        width: 48% !important;
    }

}

@media only screen and (max-width: 1200px) { {
    max-width: 100%;
    margin-bottom: 20px;
}

    .row .lowerRow2 {
        width: 48% !important;
    }

}

@media only screen and (max-width: 1024px) { {
    max-width: 900px;
    margin-bottom: 20px;
}
    .row .lowerRow1,
    .row .lowerRow2 {
        width: 80% !important;
    }

}

@media only screen and (max-width: 768px) { {
    max-width: 900px;
    margin-bottom: 36px;
}
    .row .lowerRow {
        width: 48%;
    }
}

.swiper-slide-content {
    background: #E6ECFA
}

.form-page-left-content-inner {
    margin-top: -60px !important;
}

@media only screen and (min-width: 1921px) {
    .inlineSwiper {
        width: 80% !important;
    }
    .form-page-left-content-inner {
        margin-top: -80px !important;
    }
    .row .lowerRow1{
        width: 100% !important;
        max-width: 392px;
    }
}

@media only screen and (max-width: 1441px) {
    .login-form-page {
        z-index: 0;
    }
    .form-page-header {
        z-index: 1;
    }
}
.banner-image{

}
