@media only screen and (max-width: 1024px) {
  /** multi choice crd sec CSS Start  **/
  .multi-choice-crd-list {
    grid-template-columns: repeat(3,1fr);
    }
    /** multi choice crd sec CSS End  **/

    /** teacher profile page css Start **/
    .teacher-profile-banner{
        &::after{
            height: 0;
        }
        .profile-banner-img{
            border: 5px solid #fff;
        }
        .profile-banner-txt{
            padding-bottom: 0;
        }
        padding-bottom: 80px;
    }
    /** teacher profile  page css END **/
}

@media only screen and (max-width: 991px) {
    /** Header CSS Start  **/
    .dropdown-toggle.show  .dropdown-icon{
        transform: rotate(180deg);
    }
    .navbar-collapse{
        position: fixed;
        width: 250px;
        max-width: 100%;
        top: 75px;
        right: -100%;
        height: 100%;
        min-height: 100vh;
        background-color: #fff;
        transition: 0.3s;
        padding: 20px 15px;
        padding-bottom: 85px;
        &.show{
            right: 0;
            box-shadow: rgb(245, 107,100) -10px 21px 50px -25px;
        }
    }
    .header.sticky .navbar-collapse{
        top: 65px;
    }
    .header.sticky .navbar{
        transform: none;
    }
    .header{
    }
    .dropdown-menu.show{
        margin-bottom: 10px;
    }
    /** Header CSS End  **/

    /** Course Detail css Start **/
    #course-detail-banner-bg{
        width: calc(100% + 30px);
        margin-left: -15px;
        height: 100%;
    }
    .course-detail-banner{
        position: relative;
    }
    .course-detail-grid{
        display: grid;
        grid-template-columns: 1fr ;
        grid-gap: 0 50px;
        grid-template-rows: max-content;
    }
    .course-detail-crd-btn{
        width: auto;
    }
    /** Course Detail css End **/

    /** Article Card  css Start **/
    .article-crd-list{
        grid-template-columns: repeat(2, 1fr);
    }
    /** Article Card  css End **/
   

}

@media only screen and (max-width: 768px) {
    
    /** Common Banner CSS Start  **/
    .banner-heading{
        font-size: 40px;
    }
    .banner-brief{
        font-size: 20px;
    }
    
    /** Common Banner CSS End  **/
    /** Blog Banner CSS Start  **/
    .blog-banner .banner-content{
        margin: 0  auto;
    }
    /** Blog Banner CSS End  **/
    /** Two side curve Sec css  Start**/
    .two-side-curve-content{
        padding: 50px 15px;
        border-radius: 0 70px 0 70px;
    }
    /** Two side curve Sec css  End**/

    /** Course Detail css Start **/
    .course-detail-crd-btn{
        width: 100%;
    }
    /** Course Detail css End **/
    /** Contact us css Start **/
    .contactus-item .sec-heading{
        font-size: 30px;
    }
    .contactus-item .sec-sub-heading{
        font-size: 16px;       
    }
    /** Contact us css End **/
}
@media only screen and (max-width: 767px) {
    html {
        font-size: 70%; 
    }

    /** Header Css Start **/
    .navbar-collapse{
        top: 67px;
    }
    .header.sticky .navbar-collapse{
        top: 57px;
    }
    .main-top-gap{
        margin-top: 79px;
    }
    /** Header Css End **/

    /** common code Css Start **/
    .course-card:hover{
        transform: none;
    }
    .banner-form    .index-banner-search{
        font-size: 14px;
    }
    /** common code Css End **/
    /** spacing Classes Css Start **/
    .pt-8,.py-8{
        padding-top: 50px;
    }
    .pb-8,.py-8{
        padding-bottom: 50px;
    }
    .mt-8,.my-8{
        margin-top: 50px;
    }
    .mb-8,.my-8{
        margin-bottom: 50px;
    }
    .readcrumb-sec {
        padding-bottom: 100px;
    }
    .cart-main-content {
        margin-top: -50px;
    }
      .teacher-profile-banner{
        padding-bottom: 50px;
    }
    .skilify-info-sec{
        padding: 0;
    }
    .skilify-info-points{
        top: -50px;
    }
    /** spacing Classes Css End **/

    /** Index Banner Css Start **/
    .index-banner::after, 
    .index-banner::before{
        opacity: 0.3;
    }
    /** Index Banner Css End **/

    /** card Css Start **/
    .crd-img{
        height: 260px;
    }
    /** card Css End **/

    /** Article Card  css Start **/
    .article-crd-list{
        grid-template-columns: 1fr;
    }
    /** Article Card  css End **/
    /** Blog Detail css Start **/
    .blog-detail-img img{
        height: auto;
    }
    .blog-detail-banner{
        padding-bottom: 120px;
    }
    .clap-response-wrap{
        max-width: 100%;
    }
    /** Blog Detail css End **/
    /** Courses  page css Start **/
    .courses-search .sec-header .btn-secondary{
        padding: 10px 15px 10px 15px;
    }
    .learn-steps-v3-content::after{
        width: 100%;
        height: 65%;

    }
    /** Courses  css End **/


    /** Contact us css Start **/
    .contactus-banner .banner-content{
        padding-bottom: 20px;
    }
    .contactus-item .sec-heading{
        font-size: 26px ;
    }
    .contactus-item::after{
        width: 100%;
    }
    
    /** Contact us css End **/

   
}

@media only screen and (max-width: 575px) {
    
    .main-top-gap{
        margin-top: 77px;
    }
    /** Home 2 Css Start **/
    .hero-banner-v2::after{
        border-radius: 25px;
    }
    /** Home 2 Css Css **/
    

     /** Common Banner CSS Start  **/
     .banner-heading{
        font-size: 34px!important;
    }
    .banner-brief{
        font-size: 18px!important;
    }
    /** Common Banner CSS End  **/
    /** Course Detail css Start **/
    .course-price{
        width: 100%;
    }
    /** Course Detail css End **/
    /** Cart css Start **/
    .cart-course-img{
        width: 100%;
        height: auto;
    }
    .check-out-crd, .payment-crd-wrap{
        min-width: auto;
    }
    /** Cart css End **/
     /** Card CSS Start  **/
     .card-subtitle{
        font-size: 16px;
    }
    .card-title{
        font-size: 20px;
    }
    .card-text{
        font-size: 14px;
    }
    /** Card CSS End  **/

    /** Remote Learning CSS Start  **/
    .remote-learning-content-wrap{
        padding: 0;
    }
    .remote-learning-sec::before, 
    .remote-learning-sec::after{
        width: 70px;
        height: 100px;
        background-size: contain;
    }
    /** Remote Learning CSS End  **/
    /** Course Card CSS Start  **/
    .course-card{
        padding: 10px;
        .crd-bottom{
            position: absolute;
            bottom: 10px;
            right: 10px;
            left: 10px;
        }
    }
    .crd-info-txt{
        font-size: 12px;
    }
    /** Course Card CSS End  **/

    /** Common section CSS Start  **/
    .sec-heading{
        font-size: 30px;
    }
    .sec-brief{
        font-size: 16px;
    }
    /** Common section CSS End  **/
    /** Home v3 Css Start **/
    .skilify-info-point {
        img{
            height: 35px;
            width: 35px;
        }
        .card-title{
            font-size: 18px;
        }
    }
    /** Home v3 Css End **/
    /** Two Side Curve section CSS Star  **/
    .two-side-curve-content{
        .sec-heading{
            font-size: 24px;
        }
        .sec-brief{
            font-size: 14px;
        }
    }
    /** Two Side Curve section CSS End  **/
    /** Blog Detail CSS Start  **/
    .clap-text,
    .respose-text{
        font-size: 14px;
    }

    .social-btn{
        min-width: 130px;
    }
    .author-writen-by{
        font-size: 12px;
    }
    .author-name {
        font-size: 18px;
    }

    /** Blog Detail css End **/
    /** Contact us css Satrt **/
    .contactus-banner .sub-banner-heading{
        font-size: 16px;
    }    
    /** Contact us css End **/
}   

@media only screen and (max-width: 480px) {
    
    /** Index Banner CSS Start  **/
    .index-banner{
        padding-top: 100px;
    }

    .banner-form{
        .btn.btn-primary{
            padding: 10px 15px;
            img{
                width: 25px;
            }
        }
    }
    /** Index Banner CSS End  **/

    /** Blog Detail CSS Start  **/
    
    .crd-img.blog-crd-img{
        height: auto;
    }  
    /** Blog Detail CSS End  **/

    /** Footer CSS Start  **/
    .footer-heading{
        border-bottom: 1px solid $third-color;
        position: relative;
        font-size: 20px;
        &::after,&::before{
            content: "";
            transition: 0.3s;   
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 3px;
            width: 20px;
            border-radius: 10px;
            background-color: $third-color;
        }
        &.active::after,
        &.active::before{
            background-color: #fff;
        }
        &.active::before{
            transform: translateY(-50%) rotate(270deg);
        }
    }
    .footer-links{
        font-size: 16px;
        transition: 0.3s;
        height: 0;
        overflow: hidden;
    }
    .copy-right{
        border: 0;
    }
    
    /** Footer CSS End  **/
}