/*
    Template Name    : skilify
	Description		 : HTML5 Template
    Version          : 1.0
/*
================================================
/* Table of Content
==================================================


1. Common CSS
2. Header CSS
3. Index Banner CSS
4. Card slider Sec CSS
5. Categories Cards Sec CSS
6. Remote Learning Sec CSS
7. Know About Platform Sec CSS
8. Testimonial Sec CSS
9. Partner Slider Sec CSS
10. Apply card Sec CSS
11. Footer CSS
12. Responsive CSS
*/



/*
================================================
1. Common CSS
================================================
*/
@import "common";


/*
================================================
2. Header CSS
================================================
*/
@import "header-footer/header";


/*
================================================
11. Footer CSS
================================================
*/
@import "header-footer/footer";


/*
================================================
12. Responsive CSS
================================================
*/
@import "responsive";
body{
  height: fit-content;
}
.navbar-light .navbar-nav .nav-link{
  color: #103F9F;
  padding: 0;
  font-family: "Open Sans",sans-serif;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #103F9F;
  font-size: 16px;
  font-weight: 400;
}
.dropdown-menu.show{
  margin-top: 10px;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  margin-top: 25px;
  left: -40px;
  .dropdown-item{
    color: #0642CD;
  }
  :hover, :active, :focus{
    color: #0642CD;
    background-color: transparent;
  }
}
.navtop .navbar .navbar-collapse .collapse{
  display: flex;
  width: 100px!important;
  height: 100px!important;
  justify-content: center;
  align-items: center;
}
.navtop .navbar{
  height: 110px!important;
}
