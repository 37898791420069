.detail {
  width: 100%;
  height: 100vh;
  display: flex;

  .courseList {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 100%;
    padding-bottom: 50px;

    .nav-tabs {
      display: flex;
      gap: 32px;
      height: 65px;
      padding: 0 20px;
      margin: 0;
      align-items: center;
      background: transparent !important;
      border-bottom: 1px solid #EFEFF6 !important;
    }

    .nav-tabs:hover {
      background: transparent !important;
      border: none;
      height: 100%;
      border-bottom: 1px solid black;
    }

    .nav-link {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid transparent;
      border-spacing: 20px;
      padding: 20px 0 10px 0;
      border-radius: 0;
      height: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      color: #928F9A;
    }

    .nav-link:hover {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #4A55A2;
      border-spacing: 20px;
      color: #4A55A2;
      padding: 20px 0 10px;
      height: 100%;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      border-radius: 0;
    }

    .nav-link:focus {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #4A55A2;
      border-spacing: 20px;
      color: #4A55A2;
      height: 100%;
      padding: 20px 0 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      border-radius: 0;
    }

    .nav-link.active {
      background: transparent !important;
      border: none;
      border-bottom: 2px solid #4A55A2;
      border-spacing: 20px;
      color: #4A55A2;
      height: 100%;
      padding: 20px 0 10px;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      border-radius: 0;
    }

    .contentHeader {
      background-color: #103F9F;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;

      .topSide {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-inline: 48px;
        margin-top: 40px;
        margin-bottom: 47px;

        .turnBack {
          display: flex;
          align-items: center;
          text-decoration: none !important;
          gap: 19px;

          .backButton {
            background-color: #2d539f;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 100%;

            .leftArrow {
              opacity: 100%;
              fill: white;
            }
          }

          .backText {
            padding: 0;
            margin: 0;
            color: white !important;
            font-weight: 400;
            font-size: 18px;
            font-family: "Open Sans", sans-serif;
          }
        }

        .Title {
          margin-top: 42px;
          padding: 0;
          margin-bottom: 0;
          color: white;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
          font-size: 32px;
        }
      }

      .progressComp {
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 40px;
        background-color: transparent;

        .percent {
          text-align: center;
          padding: 0;
          margin-bottom: 9px;
          font-size: 16px;
          color: white;
          font-weight: 400;
          font-family: 'Open Sans', sans-serif;
        }

        .progressBar100 {
          background-color: #43C8B1;
          width: 100%;
          min-height: 10px;
        }

        .progressBar0 {
          background-color: #43C8B1;
          width: 0%;
          min-height: 10px;
        }
      }
    }

    .searchField {
      width: 100%;
      padding-inline: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: auto;
      background-color: #EFF3FD;
      gap: 9px;

      .search {
        border: none;
        padding: 0;
        height: 70px;
        margin: 0;
        color: #302D3A;
        width: 100%;
        opacity: 50%;
        font-size: 16px;
        font-weight: 400;
        outline: none;
        font-family: "Open Sans", sans-serif;
        background-color: transparent;
      }

      ::placeholder {
        color: #928F9A;
        width: 100%;
        opacity: 50%;
      }
    }

    .lessonList {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-block: 36px;
      padding-inline: 50px;
      width: 100%;
      height: 100%;
      background-color: transparent;

      p {
        padding: 0;
        margin: 0;
      }

      .lesson {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-bottom: 50px;

        .Title {
          display: flex;
          align-items: center;
          font-size: 22px;
          padding-bottom: 15px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
          color: #0642CD;

          .Description {
            font-size: 16px;
            padding: 0;
            margin: 0;
            color: #4A55A2;
            font-weight: 400;
          }
        }

        .lessonDetail:active {
          padding-inline: 12px;
          width: 100%;
          height: 63px;
          display: flex;
          align-items: center;
          background-color: #43C8B1;
          color: white;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;

          .desc {
            margin-inline-end: 35px;
            width: 204px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .during {
            padding-inline: 12px;
            padding-block: 3px;
            background-color: rgba(255, 255, 255, 0.145);
            font-size: 12px;
            color: white;
            border-radius: 2px;
            font-weight: 400;
          }

          .Title {
            width: 100%;
          }

          .book {
            margin-inline-end: 10px;
            fill: white;
          }
        }

        .lessonactive {
          padding-inline: 12px;
          width: 100%;
          height: 63px;
          display: flex;
          align-items: center;
          background-color: #43C8B1;
          color: white;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;

          .desc {
            margin-inline-end: 35px;
            width: 204px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .during {
            padding-inline: 12px;
            padding-block: 3px;
            background-color: rgba(255, 255, 255, 0.145);
            font-size: 12px;
            color: white;
            border-radius: 2px;
            font-weight: 400;
          }

          .Title {
            width: 100%;
          }

          .book {
            margin-inline-end: 10px;
            fill: white;
          }
        }

        .lessonDetail {
          padding-inline: 12px;
          width: 100%;
          height: 63px;
          display: flex;
          align-items: center;
          background-color: #EDF3FF;
          color: #302D3A;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;

          .desc {
            margin-inline-end: 35px;
            width: 204px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .during {
            padding-inline: 12px;
            padding-block: 3px;
            background-color: rgba(91, 109, 249, 0.145);
            font-size: 12px;
            color: #0642CD;
            border-radius: 2px;
            font-weight: 400;
          }

          .book {
            margin-inline-end: 10px;
            fill: #4A55A2;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: calc(100% - 450px);

    .head {
      display: flex;
      width: 100%;
      min-height: 120px;
      max-height: 120px;
      justify-content: space-between;
      padding-inline: 50px;
      align-items: center;

      .texts {
        display: flex;
        align-items: center;

        .Title {
          font-size: 36px;
          font-weight: 600;
          padding: 0;
          margin: 0;
          font-family: "Open Sans", sans-serif;
          color: #103f9f;
        }

        .during {
          margin: 0 0 0 36px;
          width: 90px;
          height: 40px;
          background: #FEEFEE;
          font-family: "Open Sans", sans-serif;
          color: #F83B69;
          justify-content: center;
          font-size: 15px;
          font-weight: 400;
          border-radius: 5px;
          align-items: center;
          display: flex;
        }
      }

      .nextLesson {
        background-color: #0642CD;
        border: none;
        height: 57px;
        width: 179px;
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: 600;
        box-shadow: none;
      }
    }

    .vimeo {
      padding-inline: 50px;
      width: 100%;
      position: relative;

      iframe {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    .description {
      padding-inline: 50px;
      padding-block: 56px;
      display: flex;
      flex-direction: column;
      gap: 56px;

      .overview-title {
        font-size: 32px;
        padding-bottom: 32px;
        margin: 0;
        font-weight: 600;
        color: #0642CD;
        font-family: "Open Sans", sans-serif;
      }

      .overview-text {
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        color: #928F9A;
        font-family: "Open Sans", sans-serif;
        line-height: 38px;

        li {
          margin-block: 20px;
        }
      }
    }

    .bottomSlider {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: end;

      .sameContent {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;

        .navStick {
          padding-inline: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .Title {
            font-size: 40px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            color: #103F9F;
          }

          .buttons {
            display: flex;
            gap: 30px;

            .previous {
              background-color: white;
              width: 52px;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 5px;

              .leftArrow {
                stroke: #0642CD;
              }
            }

            .next {
              background-color: #0642CD;
              width: 52px;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;

              .rightArrow {
                stroke: white;
              }
            }

          }
        }

        .cards {
          padding-top: 26px;
          display: flex;
          justify-content: flex-start;
          gap: 85px;
          margin-inline: 50px;
          align-items: center;

          .sameCard {
            display: flex;
            flex-direction: column;
            width: 400px;
            padding-inline: 32px;
            box-shadow: 0 10px 20px rgba(74, 85, 162, 0.25);
            padding-top: 16px;
            padding-bottom: 19px;

            .title {
              display: flex;
              padding-top: 27px;
              align-items: center;
              justify-content: flex-start;
              gap: 20px;

              .inlineTitle {
                padding: 0;
                margin: 0;
                font-size: 22px;
                font-weight: 600;
                color: #0642CD;
              }
            }

            .miniInfos {
              display: flex;
              gap: 20px;
              padding-top: 17px;

              .size {
                background-color: rgba(254, 110, 142, 0.145);
                padding-inline: 14px;
                color: #F83B69;
                font-size: 12px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }

              .date {
                background-color: rgba(107, 130, 198, 0.145);
                font-family: "Open Sans", sans-serif;
                padding-inline: 9px;
                color: #0642CD;
                font-size: 12px;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }
            }

            .cardDesc {
              color: #928F9A;
              font-size: 14px;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
            }

            .buttons {
              display: flex;
              gap: 45px;

              .download {
                border: none;
                background-color: #FC196E;
                width: 146px;
                height: 41px;
              }

              .share {
                border: none;
                background-color: #0642CD;
                width: 146px;
                height: 41px;
              }
            }
          }
        }
      }
    }
  }

  .mobile {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
    height: fit-content;
    font-family: "Open Sans", sans-serif;

    .contentHeader {
      background-color: #103F9F;
      color: #FFFFFF;
      margin-top: 0;

      .header-top {
        font-size: 24px;
        padding-inline: 24px;
        padding-bottom: 38px;
        font-weight: 600;

        .topbar {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .progressComp {
        font-size: 14px;
        text-align: center;
        font-weight: 400;

        .progressBar {
          width: 68%;
          height: 10px;
          background-color: #43C8B1;
        }
      }
    }

    .vimeo {
      width: 100%;
      height: auto;
      display: block;
    }

    .nav-tabs {
      border-bottom: 1px solid #EFEFF6;
      height: 67px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline: 20px;
      gap: 32px;
      font-size: 16px;

      .nav-link {
        color: #928F9A !important;
        font-weight: 400;
        width: fit-content;
        height: 67px;
        border: none;
        fill: #928F9A;

        .icon {
          border: none !important;
        }
      }

      .nav-link.active {
        color: #4A55A2 !important;
        fill: #4A55A2 !important;
        font-weight: 600;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-radius: 0;
      }

      .nav-link :focus, :hover {
        color: #4A55A2 !important;
        fill: #4A55A2 !important;
        font-weight: 600;
        border: none;
        border-bottom: 2px solid #4A55A2;
      }
    }

    .content {
      width: 100%;
      height: fit-content;

      .searchField {
        width: 100%;
        padding-inline: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 56px;
        background-color: #EFF3FD;
        gap: 9px;

        .search {
          border: none;
          padding: 0;
          margin: 0;
          color: #302D3A;
          width: 100%;
          opacity: 50%;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          font-family: "Open Sans", sans-serif;
          background-color: transparent;
        }

        ::placeholder {
          color: #928F9A;
          width: 100%;
          opacity: 50%;
        }
      }

      .description {
        display: flex;
        padding-inline: 21px;
        padding-block: 20px;
        flex-direction: column;
        gap: 24px;

        .overview {
          .overview-title {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 0;
            padding-bottom: 12px;
          }

          .overview-text {
            font-size: 14px;
            padding-top: 0;
            margin-top: 0;
            font-weight: 400;
          }
        }

        .target {
          .overview-title {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 0;
            padding-bottom: 12px;
          }

          .overview-text {
            font-size: 14px;
            padding-top: 0;
            margin-top: 0;
            font-weight: 400;
          }
        }

        .bring {
          .overview-title {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 0;
            padding-bottom: 12px;
          }

          .overview-text {
            font-size: 14px;
            padding-top: 0;
            margin-top: 0;
            font-weight: 400;
          }
        }
      }

      .sameContent {
        .navStick {
          padding-inline: 20px;
          width: 100%;
          display: flex;

          .Title {
            font-size: 22px;
            font-weight: 600;
          }
        }

        .cards {
          width: 100%;
          margin-inline: 20px;
          padding-right: 40px;
          display: flex;
          overflow: scroll;
        }
      }
    }

    .lessonList {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 20px;

      .searchField {
        width: 100%;
        padding-inline: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 56px;
        background-color: #EFF3FD;
        gap: 9px;

        .search {
          border: none;
          padding: 0;
          margin: 0;
          color: #302D3A;
          width: 100%;
          opacity: 50%;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          font-family: "Open Sans", sans-serif;
          background-color: transparent;
        }

        ::placeholder {
          color: #928F9A;
          width: 100%;
          opacity: 50%;
        }
      }

      .lesson {
        display: flex;
        flex-direction: column;
        padding-inline: 20px;
        gap: 15px;

        .Title {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 0px;
          padding-bottom: 0;
          color: #0642CD;
        }

        .lessonDetail {
          width: 100%;
          height: 62px;
          padding-top: 0;
          margin-top: 0;
          padding-inline: 12px;
          background-color: #EDF3FF;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .leftDetail {
            display: flex;
            gap: 10px;
            align-items: center;
            color: #302D3A;
            fill: #4A55A2;

            p {
              padding: 0;
              margin: 0;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .duration {
            padding: 0;
            background-color: rgba(91, 109, 249, 0.145);
            padding-inline: 12px;
            padding-block: 3px;
            border-radius: 2px;
            margin: 0;
          }
        }

        .lessonDetail:nth-child(2n-1) {
          background-color: #FFF1F3;

          .leftDetail {
            display: flex;
            gap: 10px;
            align-items: center;
            color: #302D3A;
            fill: rgba(255, 109, 138, 1);

            p {
              padding: 0;
              margin: 0;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .duration {
            padding: 0;
            background-color: rgba(254, 110, 142, 0.145);
            padding-inline: 12px;
            padding-block: 3px;
            color: rgba(248, 59, 105, 1);
            border-radius: 2px;
            margin: 0;
          }
        }
      }
    }
  }
}

.detail .courseList .lessonList .lesson .lessonDetail:nth-child(2n-1) {
  padding-inline: 12px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  background-color: #FFF1F3;
  color: #302D3A;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    background-color: rgba(254, 110, 142, 0.145);
    font-size: 12px;
    color: #F83B69;
    border-radius: 2px;
    font-weight: 400;
  }

  .book {
    margin-inline-end: 10px;
    fill: #FC196E;
  }
}

.detail .courseList .lessonList .lesson .lessonDetail:nth-child(2n-1):active {
  padding-inline: 12px;
  width: 100%;
  height: 63px;
  display: flex;
  align-items: center;
  background-color: #43C8B1;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;

  .desc {
    margin-inline-end: 35px;
    width: 204px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .during {
    padding-inline: 12px;
    padding-block: 3px;
    background-color: rgba(255, 255, 255, 0.145);
    font-size: 12px;
    color: white;
    border-radius: 2px;
    font-weight: 400;
  }

  .Title {
    width: 100%;
  }

  .book {
    margin-inline-end: 10px;
    fill: white;
  }
}

@media only screen and (max-width: 1920px) {
  .detail {
    width: 100%;
    height: 100vh;
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: calc(100% - 450px);

      .head {
        display: flex;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        justify-content: space-between;
        padding-inline: 50px;
        align-items: center;

        .texts {
          display: flex;
          align-items: center;

          .Title {
            font-size: 36px;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-family: "Open Sans", sans-serif;
            color: #103f9f;
          }

          .during {
            margin: 0 0 0 36px;
            max-width: 90px;
            max-height: 40px;
            width: auto;
            height: auto;
            padding-block: 7px;
            padding-inline: 20px;
            background: #FEEFEE;
            font-family: "Open Sans", sans-serif;
            color: #F83B69;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            border-radius: 5px;
            align-items: center;
            display: flex;
          }
        }

        .nextLesson {
          background-color: #0642CD;
          border: none;
          max-height: 57px;
          height: auto;
          max-width: 179px;
          width: auto;
          padding-inline: 32px;
          padding-block: 16px;
          font-family: "Open Sans", sans-serif;
          font-size: 15px;
          font-weight: 600;
          box-shadow: none;
        }
      }

      .vimeo {
        padding-inline: 50px;
        width: 100%;
        position: relative;

        iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      .description {
        padding-inline: 50px;
        padding-block: 56px;
        display: flex;
        flex-direction: column;
        gap: 56px;

        .overview-title {
          font-size: 30px;
          padding-bottom: 32px;
          margin: 0;
          font-weight: 600;
          color: #0642CD;
          font-family: "Open Sans", sans-serif;
        }

        .overview-text {
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          color: #928F9A;
          font-family: "Open Sans", sans-serif;
          line-height: 34px;

          li {
            margin-block: 20px;
          }
        }
      }

      .sameContent {
        .navStick {
          padding-inline: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .Title {
            font-size: 40px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            color: #103F9F;
          }

          .buttons {
            display: flex;
            gap: 30px;

            .previous {
              background-color: white;
              width: 52px;
              border: none;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 5px;

              .leftArrow {
                stroke: #4A55A2;
              }
            }

            .next {
              background-color: #4A55A2;
              width: 52px;
              height: 52px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;

              .rightArrow {
                stroke: white;
              }
            }

          }
        }

        .cards {
          padding-top: 26px;
          display: flex;
          justify-content: flex-start;
          gap: 5%;
          margin-inline: 50px;
          align-items: center;

          .sameCard {
            display: flex;
            flex-direction: column;
            width: 30%;
            padding-inline: 32px;
            box-shadow: 0 10px 20px rgba(74, 85, 162, 0.25);
            padding-top: 16px;
            padding-bottom: 19px;

            .title {
              display: flex;
              padding-top: 27px;
              align-items: center;
              justify-content: flex-start;
              gap: 20px;

              .inlineTitle {
                padding: 0;
                margin: 0;
                font-size: 22px;
                font-weight: 600;
                color: #0642CD;
              }
            }

            .miniInfos {
              display: flex;
              gap: 20px;
              padding-top: 17px;

              .size {
                background-color: rgba(254, 110, 142, 0.145);
                padding-inline: 14px;
                color: #F83B69;
                font-size: 12px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }

              .date {
                background-color: rgba(107, 130, 198, 0.145);
                font-family: "Open Sans", sans-serif;
                padding-inline: 9px;
                color: #0642CD;
                font-size: 12px;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }
            }

            .cardDesc {
              color: #928F9A;
              font-size: 14px;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
            }

            .buttons {
              display: flex;
              gap: 45px;

              .download {
                border: none;
                background-color: #FC196E;
                width: 146px;
                height: 41px;
              }

              .share {
                border: none;
                background-color: #0642CD;
                width: 146px;
                height: 41px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .detail {
    width: 100%;
    height: 100vh;
    display: flex;

    .content {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: calc(100% - 450px);

      .head {
        display: flex;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        justify-content: space-between;
        padding-inline: 50px;
        align-items: center;

        .texts {
          display: flex;
          align-items: center;

          .Title {
            font-size: 36px;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-family: "Open Sans", sans-serif;
            color: #103f9f;
          }

          .during {
            margin: 0 0 0 36px;
            max-width: 90px;
            max-height: 40px;
            width: auto;
            height: auto;
            padding-block: 5px;
            padding-inline: 18px;
            background: #FEEFEE;
            font-family: "Open Sans", sans-serif;
            color: #F83B69;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            border-radius: 5px;
            align-items: center;
            display: flex;
          }
        }

        .nextLesson {
          background-color: #0642CD;
          border: none;
          max-height: 57px;
          height: auto;
          max-width: 179px;
          width: auto;
          padding-inline: 28px;
          padding-block: 12px;
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          font-weight: 600;
          box-shadow: none;
        }
      }

      .vimeo {
        padding-inline: 50px;
        width: 100%;
        position: relative;

        iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      .description {
        padding-inline: 50px;
        padding-block: 56px;
        display: flex;
        flex-direction: column;
        gap: 56px;

        .overview-title {
          font-size: 30px;
          padding-bottom: 32px;
          margin: 0;
          font-weight: 600;
          color: #0642CD;
          font-family: "Open Sans", sans-serif;
        }

        .overview-text {
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          color: #928F9A;
          font-family: "Open Sans", sans-serif;
          line-height: 34px;

          li {
            margin-block: 20px;
          }
        }
      }

      .sameContent {
        .navStick {
          padding-inline: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .Title {
            font-size: 36px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .buttons {
            display: flex;
            gap: 30px;

            .previous {
              background-color: white;
              width: 52px;
              border: none;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 5px;

              .leftArrow {
                stroke: #0642CD;
              }
            }

            .next {
              background-color: #0642CD;
              width: 52px;
              height: 52px;
              display: flex;
              align-items: center;
              border: none;
              justify-content: center;
              border-radius: 5px;

              .rightArrow {
                stroke: white;
              }
            }

          }
        }

        .cards {
          padding-top: 26px;
          display: flex;
          justify-content: flex-start;
          gap: 5%;
          margin-inline: 50px;
          align-items: center;

          .sameCard {
            display: flex;
            flex-direction: column;
            width: 30%;
            min-width: 260px;
            padding-inline: 12px;
            box-shadow: 0 10px 20px rgba(74, 85, 162, 0.25);
            padding-top: 8px;
            padding-bottom: 19px;

            img {
              width: 100%;
              max-height: 156px;
            }

            .title {
              display: flex;
              padding-top: 27px;
              align-items: center;
              justify-content: flex-start;
              gap: 20px;

              .inlineTitle {
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                color: #0642CD;
              }
            }

            .miniInfos {
              display: flex;
              gap: 20px;
              padding-top: 17px;

              .size {
                background-color: rgba(254, 110, 142, 0.145);
                padding-inline: 14px;
                color: #F83B69;
                font-size: 12px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }

              .date {
                background-color: rgba(107, 130, 198, 0.145);
                font-family: "Open Sans", sans-serif;
                padding-inline: 9px;
                color: #0642CD;
                font-size: 12px;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }
            }

            .cardDesc {
              color: #928F9A;
              font-size: 14px;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
            }

            .buttons {
              display: flex;
              justify-content: space-between;
              gap: 9px;

              .download {
                border: none;
                background-color: #FC196E;
                max-width: 146px;
                width: 100%;
                max-height: 41px;
                height: 100%;
              }

              .share {
                border: none;
                background-color: #0642CD;
                max-width: 146px;
                width: 100%;
                max-height: 41px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .detail {
    width: 100%;
    height: 100vh;
    display: flex;

    .courseList {
      display: flex;
      flex-direction: column;
      width: 450px;
      height: 100%;
      padding-bottom: 50px;

      .nav-tabs {
        display: flex;
        gap: 32px;
        height: 65px;
        padding: 0 20px;
        margin: 0;
        align-items: center;
        background: transparent !important;
        border-bottom: 1px solid #EFEFF6 !important;
      }

      .nav-tabs:hover {
        background: transparent !important;
        border: none;
        height: 100%;
        border-bottom: 1px solid black;
      }

      .nav-link {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid transparent;
        border-spacing: 20px;
        padding: 20px 0 10px 0;
        border-radius: 0;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #928F9A;
      }

      .nav-link:hover {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        padding: 20px 0 10px;
        height: 100%;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        border-radius: 0;
      }

      .nav-link:focus {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .nav-link.active {
        background: transparent !important;
        border: none;
        border-bottom: 2px solid #4A55A2;
        border-spacing: 20px;
        color: #4A55A2;
        height: 100%;
        padding: 20px 0 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        border-radius: 0;
      }

      .contentHeader {
        background-color: #103F9F;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;

        .topSide {
          margin-inline: 48px;
          margin-top: 40px;
          margin-bottom: 47px;

          .turnBack {
            display: flex;
            align-items: center;
            text-decoration: none !important;
            gap: 19px;

            .backButton {
              background-color: #103F9F;
              display: flex;
              position: relative;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              border-radius: 100%;

              .leftArrow {
                opacity: 100%;
                fill: white;
              }
            }

            .backText {
              padding: 0;
              margin: 0;
              color: white !important;
              font-weight: 400;
              font-size: 18px;
              font-family: "Open Sans", sans-serif;
            }
          }

          .turnBack:hover, :focus, :active {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .Title {
            margin-top: 42px;
            padding: 0;
            margin-bottom: 0;
            color: white;
            font-size: 32px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
          }
        }

        .progressComp {
          justify-content: center;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 40px;
          background-color: transparent;

          .percent {
            text-align: center;
            padding: 0;
            margin-bottom: 9px;
            font-size: 16px;
            color: white;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
          }

          .progressBar {
            background-color: #43C8B1;
            width: 67%;
            min-height: 10px;
          }
        }
      }

      .searchField {
        width: 100%;
        padding-inline: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        background-color: #EFF3FD;
        gap: 9px;

        .search {
          border: none;
          padding: 0;
          margin: 0;
          color: #302D3A;
          width: 100%;
          opacity: 50%;
          font-size: 16px;
          font-weight: 400;
          outline: none;
          font-family: "Open Sans", sans-serif;
          background-color: transparent;
        }

        ::placeholder {
          color: #928F9A;
          width: 100%;
          opacity: 50%;
        }
      }

      .lessonList {
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin-block: 36px;
        padding-inline: 50px;
        width: 100%;
        height: 100%;
        background-color: transparent;

        p {
          padding: 0;
          margin: 0;
        }

        .lesson {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-bottom: 50px;

          .Title {
            font-size: 22px;
            padding-bottom: 15px;
            font-weight: 600;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .lessonDetail:active {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #43C8B1;
            color: white;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(255, 255, 255, 0.145);
              font-size: 12px;
              color: white;
              border-radius: 2px;
              font-weight: 400;
            }

            .Title {
              width: 100%;
            }

            .book {
              margin-inline-end: 10px;
              fill: white;
            }
          }

          .lessonDetail {
            padding-inline: 12px;
            width: 100%;
            height: 63px;
            display: flex;
            align-items: center;
            background-color: #EDF3FF;
            color: #302D3A;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 400;

            .desc {
              margin-inline-end: 35px;
              width: 204px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .during {
              padding-inline: 12px;
              padding-block: 3px;
              background-color: rgba(91, 109, 249, 0.145);
              font-size: 12px;
              color: #0642CD;
              border-radius: 2px;
              font-weight: 400;
            }

            .book {
              margin-inline-end: 10px;
              fill: #4A55A2;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: calc(100% - 450px);

      .head {
        display: flex;
        width: 100%;
        min-height: 120px;
        max-height: 120px;
        justify-content: space-between;
        padding-inline: 50px;
        align-items: center;

        .texts {
          display: flex;
          align-items: center;

          .Title {
            font-size: 34px;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-family: "Open Sans", sans-serif;
            color: #0642CD;
          }

          .during {
            margin: 0 0 0 36px;
            max-width: 90px;
            max-height: 40px;
            width: auto;
            height: auto;
            padding-block: 5px;
            padding-inline: 18px;
            background: #FEEFEE;
            font-family: "Open Sans", sans-serif;
            color: #F83B69;
            justify-content: center;
            font-size: 10px;
            font-weight: 400;
            border-radius: 5px;
            align-items: center;
            display: flex;
          }
        }

        .nextLesson {
          background-color: #0642CD;
          border: none;
          max-height: 57px;
          height: auto;
          max-width: 179px;
          width: auto;
          padding-inline: 28px;
          padding-block: 12px;
          font-family: "Open Sans", sans-serif;
          font-size: 10px;
          font-weight: 600;
          box-shadow: none;
        }
      }

      .vimeo {
        padding-inline: 50px;
        width: 100%;
        position: relative;

        iframe {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      .description {
        padding-inline: 50px;
        padding-block: 56px;
        display: flex;
        flex-direction: column;
        gap: 56px;

        .overview-title {
          font-size: 30px;
          padding-bottom: 32px;
          margin: 0;
          font-weight: 600;
          color: #0642CD;
          font-family: "Open Sans", sans-serif;
        }

        .overview-text {
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          color: #928F9A;
          font-family: "Open Sans", sans-serif;
          line-height: 34px;

          li {
            margin-block: 20px;
          }
        }
      }

      .sameContent {
        .navStick {
          padding-inline: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .Title {
            font-size: 40px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            font-family: "Open Sans", sans-serif;
            color: #103F9F;
          }

          .buttons {
            display: flex;
            gap: 30px;

            .previous {
              background-color: white;
              width: 52px;
              border: none;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 5px;

              .leftArrow {
                stroke: #4A55A2;
              }
            }

            .next {
              background-color: #0642CD;
              width: 52px;
              height: 52px;
              display: flex;
              border: none;
              align-items: center;
              justify-content: center;
              border-radius: 5px;

              .rightArrow {
                stroke: white;
              }
            }

          }
        }

        .cards {
          padding-top: 26px;
          display: flex;
          overflow: scroll;
          justify-content: flex-start;
          gap: 5%;
          margin-inline: 50px;
          align-items: center;

          .sameCard {
            display: flex;
            flex-direction: column;
            width: 30%;
            min-width: 260px;
            padding-inline: 12px;
            box-shadow: 0 10px 20px rgba(74, 85, 162, 0.25);
            padding-top: 8px;
            padding-bottom: 19px;

            img {
              width: 100%;
              max-height: 156px;
            }

            .title {
              display: flex;
              padding-top: 27px;
              align-items: center;
              justify-content: flex-start;
              gap: 20px;

              .inlineTitle {
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                color: #0642CD;
              }
            }

            .miniInfos {
              display: flex;
              gap: 20px;
              padding-top: 17px;

              .size {
                background-color: rgba(254, 110, 142, 0.145);
                padding-inline: 14px;
                color: #F83B69;
                font-size: 12px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }

              .date {
                background-color: rgba(107, 130, 198, 0.145);
                font-family: "Open Sans", sans-serif;
                padding-inline: 9px;
                color: #0642CD;
                font-size: 12px;
                font-weight: 400;
                padding-block: 3px;
                border-radius: 2px;
              }
            }

            .cardDesc {
              color: #928F9A;
              font-size: 14px;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
            }

            .buttons {
              display: flex;
              justify-content: space-between;
              gap: 9px;

              .download {
                border: none;
                background-color: #FC196E;
                max-width: 146px;
                width: 100%;
                max-height: 41px;
                height: 100%;
              }

              .share {
                border: none;
                background-color: #0642CD;
                max-width: 146px;
                width: 100%;
                max-height: 41px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .detail {
    width: 100%;
    height: fit-content;
    display: flex;

    .mobile {

    }

  }
}

////////////
.course-card-user-detail-accordion .accordion-item:nth-of-type(odd) .accordion-button:nth-of-type(odd) {
  background: #EDF3FF !important;
  display: flex;
  justify-content: space-between !important;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(even) .accordion-button:nth-of-type(odd) {
  background: #FFF1F3 !important;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(even) .accordion-button:nth-of-type(odd) .course-card-detail-accordion-button-container {
  position: relative;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(even) .accor-body .duration-page-detail {
  width: 55px;
  height: 22px;
  background: rgba(252, 25, 110, .145) !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FC196E;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(odd) .accor-body .duration-page-detail {
  width: 55px;
  height: 22px;
  background: rgba(6, 66, 205, .145) !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Main-Color, #00188F);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(odd) .accor-body .book-icon {
  filter: brightness(0) saturate(100%) invert(46%) sepia(89%) saturate(3080%) hue-rotate(206deg) brightness(99%) contrast(104%);
}


.accordion-header {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.course-card-detail-accordion-duration {
  color: #00188F;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.user-detail-courses-documents-title {
  color: #00188F;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.document-card {
  border-radius: 2px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 24, 143, 0.20);
  display: flex;
  width: 400px;
  padding: 16px 31px 19px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-course-detail {
  margin-top: -85px !important;
}

.document-card-image img {
  width: 336.545px;
  height: 263px;
}

.btn-pink {
  width: 146px;
  height: 41px;
  background: #FC196E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none !important;
}

.btn-blue {
  width: 146px;
  height: 41px;
  background: #0642CD !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none !important;
}

.btn-blue:hover {
  box-shadow: none !important;
  overflow: hidden !important;
  outline: none !important;
  text-decoration: none !important;
}

.btn-blue2 {
  display: inline-flex;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  width: 159px;
  height: 47px;
  background: #00188F;
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.document-card-name {
  color: #00188F;

  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.document-card-desc {
  color: #A3B4BF;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.document-card-description {

  font-family: "Poppins", sans-serif;
  color: #818BA7;


  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.document-card-page-title h2 {
  color: #3F7DFF;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.duration-page {
  color: var(--Auxiliary-Color, #FF00D2);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.duration-page-detail {
  color: var(--Auxiliary-Color, #FF00D2);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordion-scroll {
  height: 620px;
  overflow-y: auto;

}


.scrollbar-left {
  direction: rtl;
  padding-left: 60px;
}

/* Sadece Chrome için */
.accordion-scroll::-webkit-scrollbar {
  width: 8px;

}

/* Sadece Chrome için */
.accordion-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #00188F;
}

/* Sadece Chrome için */
.accordion-scroll::-webkit-scrollbar-track {
  background-color: #F9F9F9;

}

/* Sadece Firefox için */
.accordion-scroll {
  scrollbar-width: thin;
  scrollbar-color: #00188F #F9F9F9;
}

@media only screen and (min-width: 1921px) {
  .accordion-scroll {
    height: 900px !important;
    overflow-y: auto;

  }
}

.accordion-content {
  margin: 0px 40px;
}

@media only screen and (min-width: 993px) {
  .accor-body {
    padding: 15px 10px 15px 10px;
    width: 100% !important;
  }

  .user-course-detail-item-left-page-title-content {

    background: #00188F;
    width: 450px !important;

  }
}


.course-card-user-detail-accordion .accordion-item:nth-of-type(even) .accor-body .duration-page-detail {
  width: 55px;
  height: 22px;
  background: rgba(255, 242, 251, .8);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FC196E;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(odd) .accor-body .duration-page-detail {
  width: 55px;
  height: 22px;
  background: rgba(229, 242, 255, .5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Main-Color, #00188F);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-card-user-detail-accordion .accordion-item:nth-of-type(odd) .accor-body .book-icon {
  filter: brightness(0) saturate(100%) invert(46%) sepia(89%) saturate(3080%) hue-rotate(206deg) brightness(99%) contrast(104%);
}

.overview {

  h2{
    color: var(--Black-Text, #103F9F);

    /* H4 */
    font-family: Open Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom:10px
  }
  p{
    color: var(--Soft-Text, #7185B8);

    /* Paragraf 3 */
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

//
.share:hover, share:focus {
  box-shadow: none !important;
  outline: none !important;
}

.accordion-item, .accordion-item:focus, .accordion-item:active,
.accordion-header, .accordion-header:focus, .accordion-header:active,
.accordion-button, .accordion-button:focus, .accordion-button:active {
  outline: none !important;
}

.card-text-course-detail {
  color: #818BA7;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.card-title-course-detail {
  color: #00188F;
  font-family: "Poppins", sans-serif;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.card-title-course-detail-content {
  color: #00188F;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.course-card-detail-accordion-name {
  color: var(--Black-Text, #103F9F);

  /* Paragraf 3 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course-card-detail-accordion-desc {
  color: #818BA7 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordion .accordion-item:nth-of-type(odd) .accordion-button:nth-of-type(odd) {
  background: #E5F2FF !important;
  display: flex;
  justify-content: space-between !important;
}

.accordion .accordion-item:nth-of-type(odd) .accordion-body, .accordion .accordion-item:nth-of-type(even) .accordion-body {
  background: #fff !important;
}

.accordion .accordion-item:nth-of-type(odd) .accor-body {
 /* background: #E5F2FF !important;*/
  margin-bottom: 20px;
  padding: 10px 14px 10px 14px;
  border-bottom:1px solid #EFEFF6;
}

.accordion .accordion-item:nth-of-type(even) .accor-body {
/*  background: #FFF1F3 !important;*/
  margin-bottom: 20px;
  border-bottom:1px solid #EFEFF6;
}

.accordion .accordion-item:nth-of-type(even) .accor-body .book-icon {
  filter: brightness(0) saturate(100%) invert(33%) sepia(77%) saturate(7499%) hue-rotate(327deg) brightness(103%) contrast(98%);
}

.accordion .accordion-item:nth-of-type(even) .accordion-button:nth-of-type(odd) {
  background: #FFF1F3 !important;

}

.accordion-header {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.lecture-name {

  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #103F9F;


}

//
.correctAnswerOption {
  background: #36D6BA;
}

.correctAnswerOption .lesson-page-question-title {
  color: #fff !important;
}

.notCorrectAnswerOption {
  background: #F56962;
}

.notCorrectAnswerOption .lesson-page-question-title {
  color: #fff !important;
}

.lesson-page-textarea-border {
  border: 1px solid #F56962 !important;
}

.lesson-page-questions-title {
  color: #302D3A;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lesson-page-questions {

}

.lesson-page-question {
  border: 1px solid #C1CDE2;
  padding: 10px 30px;
  border-radius: 4px;
}

.lesson-page-question-title {
  color: var(--Soft-Text, #7185B8);
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.question-post-scroll {
  height: 560px;
  overflow-y: auto;
}

@media only screen and (min-width: 1921px) {
  .question-post-scroll {
    height: 1120px;
  }
}


.question-post-scroll::-webkit-scrollbar {
  width: 8px;
  height: 14px !important;
}

.question-post-scroll::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #00188F;
}

.question-post-scroll::-webkit-scrollbar-track {
  background-color: #F9F9F9;
}


.question-scroll {
  height: 560px;
  overflow-y: auto;
}


@media only screen and (min-width: 1921px) {
  .question-scroll {
    height: 620px;
  }
}


.question-scroll::-webkit-scrollbar {
  width: 8px;
  height: 14px !important;
}

.question-scroll::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #00188F;
}

.question-scroll::-webkit-scrollbar-track {
  background-color: #F9F9F9;
}


.lesson-page-textarea-content {
  width: 100% !important;
}

.lesson-page-textarea {
  padding: 10px 10px;
  border-radius: 4px;
  border: none !important;
  border: 1px solid #C1CDE2 !important;
  background: transparent !important;


}

.lesson-page-textarea::placeholder {
  color: #7185B8 !important;
  padding: 5px 15px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  line-height: normal;
}

textarea:focus {
  outline: none !important;
  background: #EFF2F6 !important;
}

.status-text {
  color: #FFF;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.selected-option {
  background: #0642CD !important;
}

.selected-option .lesson-page-question-title {
  color: #FFF !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #FFF;
  font-family: "Poppins", sans-serif;

}

.accordion-item, .accordion-item:focus, .accordion-item:active, .accordion-header, .accordion-header:focus, .accordion-header:active, .accordion-button, .accordion-button:focus, .accordion-button:active {
  box-shadow: none !important;
}

.accordion-item, .accordion-header, .accordion-header:active, .accordion-button, .accordion-button:active {
  border: none !important;
}

#second-accordion .accordion-button::after {
  display: none !important;
}

.bottomSlider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;

  .sameContent {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .navStick {
      padding-inline: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Title {
        font-size: 40px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        font-family: "Open Sans", sans-serif;
        color: #103F9F;
      }

      .buttons {
        display: flex;
        gap: 30px;

        .previous {
          background-color: white;
          width: 52px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 5px;

          .leftArrow {
            stroke: #0642CD;
          }
        }

        .next {
          background-color: #0642CD;
          width: 52px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;

          .rightArrow {
            stroke: white;
          }
        }

      }
    }

    .cards {
      padding-top: 26px;
      display: flex;
      justify-content: flex-start;
      gap: 85px;
      margin-inline: 50px;
      align-items: center;

      .sameCard {
        display: flex;
        flex-direction: column;
        width: 400px;
        padding-inline: 32px;
        box-shadow: 0 10px 20px rgba(74, 85, 162, 0.25);
        padding-top: 16px;
        padding-bottom: 19px;

        .title {
          display: flex;
          padding-top: 27px;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          .inlineTitle {
            padding: 0;
            margin: 0;
            font-size: 22px;
            font-weight: 600;
            color: #0642CD;
          }
        }

        .miniInfos {
          display: flex;
          gap: 20px;
          padding-top: 17px;

          .size {
            background-color: rgba(254, 110, 142, 0.145);
            padding-inline: 14px;
            color: #F83B69;
            font-size: 12px;
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            padding-block: 3px;
            border-radius: 2px;
          }

          .date {
            background-color: rgba(107, 130, 198, 0.145);
            font-family: "Open Sans", sans-serif;
            padding-inline: 9px;
            color: #0642CD;
            font-size: 12px;
            font-weight: 400;
            padding-block: 3px;
            border-radius: 2px;
          }
        }

        .cardDesc {
          color: #928F9A;
          font-size: 14px;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
        }

        .buttons {
          display: flex;
          gap: 45px;

          .download {
            border: none;
            background-color: #FC196E;
            width: 146px;
            height: 41px;
          }

          .share {
            border: none;
            background-color: #0642CD;
            width: 146px;
            height: 41px;
          }
        }
      }
    }
  }
}

.texts {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .texts .Title {
    font-size: 33px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    color: #103f9f;
/*    padding-left: 20px;*/
    margin-bottom: 14px
  }
}


.texts .during {
  margin: 0 0 0 36px;
  max-width: 90px;
  max-height: 40px;
  width: auto;
  height: auto;
  padding-block: 5px;
  padding-inline: 18px;
  background: #FEEFEE;
  font-family: "Open Sans", sans-serif;
  color: #F83B69;
  justify-content: center;
  font-size: 10px;
  font-weight: 400;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .description {
    padding: 20px;
  }
  .lessonList {
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .head {
    margin-left: 0px !important;
  }

}

.nav-tabs {
  display: flex;
  gap: 32px;
  height: 65px;
  padding: 0 20px;
  margin: 0;
  align-items: center;
  background: transparent !important;
  border-bottom: 1px solid #EFEFF6 !important;
}

.nav-tabs:hover {
  background: transparent !important;
  border: none;
  height: 100%;
  border-bottom: 1px solid black;
}

.nav-link {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid transparent;
  border-spacing: 20px;
  padding: 20px 0 10px 0;
  border-radius: 0;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #928F9A;
}

.nav-link:hover {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid #4A55A2;
  border-spacing: 20px;
  color: #4A55A2;
  padding: 20px 0 10px;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  border-radius: 0;
}

.nav-link:focus {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid #4A55A2;
  border-spacing: 20px;
  color: #4A55A2;
  height: 100%;
  padding: 20px 0 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 0;
}

.nav-link.active {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid #4A55A2;
  border-spacing: 20px;
  color: #4A55A2;
  height: 100%;
  padding: 20px 0 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 0;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #103F9F !important;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #103F9F !important;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none !important;
}

#basic-nav-dropdown {
  border: none !important;
}

@media only screen and (max-width: 992px) {
  .contentHeader {
    background-color: #103F9F;
    color: #FFFFFF;
    margin-top: 0;
    padding: 20px;

    .header-top {
      font-size: 24px;
      padding-inline: 24px;
      padding-bottom: 38px;
      font-weight: 600;

      .topbar {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .progressComp {
      font-size: 14px;
      text-align: center;
      font-weight: 400;

      .progressBar {
        width: 68%;
        height: 10px;
        background-color: #43C8B1;
      }
    }

    .Title {
      margin-top: 12px;
      padding: 0;
      margin-bottom: 0;
      color: white;
      font-size: 27px;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }

}

@media only screen and (max-width: 500px) {
  .progressComp {
    margin-top: 20px
  }
  .bottomSlider {
    margin-left: -30px;
  }
  .bottomSlider .sameContent .cards .sameCard {
    width: 120.5% !important;
  }
  .bottomSlider .sameContent .navStick .buttons {
    display: none;
  }
  .btn-pink, .btn-blue {
    margin-top: 10px;
    width: 120px;
  }
  .backText {
    display: none !important;
  }


}
@media only screen and (max-width: 992px) {
  .contentHeader{
    margin-top:-20px!important;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--Main-Color, #0642CD) !important;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 2px solid #0642CD;
  }
  .head, .vimeo{
    padding: 0px 20px!important;
  }
  .course-card-user-detail-accordion{
    margin-top:40px!important;
  }
  .head{
    margin-left: -20px!important;
  }
}
.btn-quiz-start{
  width: 100%;
  height: 57px;
  flex-shrink: 0;
  background: #0642CD;
}.btn-quiz-start-text{
   color: #FFF;
   font-family: Open Sans;
   font-size: 18px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
 }
 .lecture-name:hover{
   color:#11D7B4!important;
   transition: ease-in .1s ;
 }
.contentHeader {
  .progressBar100{
    background: #11D7B4!important;
    height: 10px!important;
    margin-bottom: -16px;
  }
}
@media only screen and (max-width: 768px){
.texts .Title {
  font-size: 22px!important;
}
  .texts .during{
    width:100px!important;
    max-width: 400px !important;
  }
  .progressBar100{
    margin-bottom: -20px!important;
  }

}
@media only screen and (max-width: 992px){
  .detail .courseList{
    width: 100%!important;
  }
  .detail .courseList .searchField{
    width: 100%!important;
  }
}
