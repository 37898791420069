/*status*/
.status-content{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    padding:20px 31px;
}
.status-content-title{
    font-family: "Poppins",sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.status-content-description{
    font-family: "Poppins",sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
/*status error*/
.status-content-error{
    background: #FFF1F3;
    border-left:5px solid #FC196E;
}
.status-content-error-title{
    color: #FC196E;
}
.status-content-error-description{
    color:  #FC196E;
}
/*
status success*/
.status-content-success{
    background: #EDF3FF;
    border-left:5px solid #0642CD;
}
.status-content-success-title{
    color: #0642CD;
}
.status-content-success-description{
    color:  #0642CD;
}
/*forgot-password-status*/
.forgot-password-status-content{
    display: flex;
    width: 100%;
    padding: 36px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #D5ECFF;
    background:  #FFF;
    box-shadow: 0px 2px 8px 0px rgba(6, 66, 205, 0.15);
}
.forgot-password-status-content-title{
    font-family: "Poppins",sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}.forgot-password-status-content-description{
     font-family: Open Sans;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 26px;
    margin-top:-15px!important;
 }
.forgot-password-status-content-success-title{
    color:  #103F9F;
}
.forgot-password-status-content-success-description{
    color: #7185B8;
}
